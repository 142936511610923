import React from 'react'
import {Link} from 'react-router-dom'
import ana from './images/ana.svg'
import nac from './images/nac.svg'
import una from './images/una.svg'
import sherpaCup from './images/sherpaCup.svg'

import './trophy.css'

function Trophy(){
    function handleOnclick(){
        window.scroll({
            top:0,
            left:0,  
        });
    }
    return(
        <div className="container-fluid trophy-fluid py-1" id="club-trophies">
                    <div className="container trophy-container py-4 text-center">
                        <div className="row mb-3">
                            <div className="col mb-4">
                                <h1 style={{color:'white'}}  id="trophies-heading">CHAMPION AWARDS</h1>
                            </div>
                        </div>
                        
                        <div className="trophy-card-wrapper">
                            <div className="trophy-card"> 
                                <div className="trophy-card-inner">
                                    <img src={ana} alt="" className="trophy-img" />  
                                </div>
                                <div className="card-info trophy-card-body" >
                                    <h1 className="text-nowrap">ANA</h1> 
                                    <h2>02</h2>
                                </div>
                            </div>
                        
                            <div className="trophy-card"> 
                                <div className="trophy-card-inner">
                                    <img src={nac} alt="" className="trophy-img" />  
                                </div>
                                <div className="card-info trophy-card-body" >
                                    <h1 className="text-nowrap">NAC</h1> 
                                    <h2>01</h2>
                                </div>
                            </div>
                        
                        
                            <div className="trophy-card"> 
                                <div className="trophy-card-inner">
                                    <img src={una} alt="" className="trophy-img" />  
                                </div>
                                <div className="card-info trophy-card-body" >
                                    <h1 className="text-nowrap">UNA</h1> 
                                    <h2>02</h2>
                                </div>
                            </div>
                        
                        
                            <div className="trophy-card"> 
                                <div className="trophy-card-inner">
                                    <img src={sherpaCup} alt="" className="trophy-img" />  
                                </div>
                                <div className="card-info trophy-card-body">
                                    <h1 className="">SHERPA CUP</h1> 
                                    <h2>05</h2>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                            <div className="col text-center">
                                <Link to="/trophy-history">
                                    <button type="button" className="btn btn-outline-warning  waves-effect many-more-button" onClick={handleOnclick}>Many More +</button>
                                </Link>
                                <br />
                                <br />
                                <br />
                            </div>
                    </div>
        </div>
    )
}

export default Trophy
