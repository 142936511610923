import {
    SET_PUBLIC_TRAINING_SCHEDULE,
    SET_PUBLIC_TRAINING_SCHEDULE_FAIL
} from '../actions/types'

const initialState = {
    trainingSchedule:{}
}

export default function(state=initialState, {type,payload}) {
    switch(type){
        case SET_PUBLIC_TRAINING_SCHEDULE:
            return {...state, trainingSchedule:payload}
        case SET_PUBLIC_TRAINING_SCHEDULE_FAIL:
            return{...state, trainingSchedule:{}}
        default:
            return state
    }
}