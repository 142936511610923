import axios from 'axios'
import {
    SET_PUBLIC_TRAINING_SCHEDULE,
    SET_PUBLIC_TRAINING_SCHEDULE_FAIL
} from './types'

export const getPublicTraining = () => async dispatch=>{
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/training/public/training-schedule')
        dispatch({
            type:SET_PUBLIC_TRAINING_SCHEDULE,
            payload:data
        })
    } catch (err) {
        dispatch({
            type:SET_PUBLIC_TRAINING_SCHEDULE_FAIL
        })
    }
}