import React from 'react'
import './footer.css'

function Footer() {
    function onClick(){
        window.scroll({
            top:0,
            left:0,  
            behavior: 'smooth' 
           });
    }
    return(
        <div className="container-fluid py-1" id="sticky-footer">
            <footer>
                <div className="container text-center footer-container">
                    <p className="">Copyright 2020 &copy; nynfc.com |&nbsp;</p>
                    <p className="">
                        <a href="https://www.bibashkc.com/" alt="bibash kc web developer page">www.bibashkc.com</a>
                    </p>
                </div>
            </footer>
            <div className="up" id="up">
                    <i className="fas fa-chevron-up" onClick={onClick}></i>
            </div>
        </div>
    )
}

export default Footer