import React from 'react'
import medal from './images/medal.svg'
import trophyBg from './images/trophy-his-bg.png'


import './trophyhistory.css'

function Trophyhistory(){
    return (
        <div className="container-fluid trophy-history-container-main py-5">
            <img src={trophyBg} alt="" className="trophy-history-bg"/>
            <div className="container trophy-his-container">
                <h1 id="trophy-history-heading">Honors</h1>
                <img src={medal} alt="" id="medal-img"/>
                <div className="timeline">
                    <ul>
                        <li className="date" date-date="2022">
                            <i className="fas fa-trophy"></i>
                            <h1>NCCNY</h1>   
                        </li>
                        <li className="date" date-date="2022">
                            <i className="fas fa-trophy"></i>
                            <h1>NYNFC cup</h1>   
                        </li>
                        <li className="date" date-date="2021">
                            <i className="fas fa-trophy"></i>
                            <h1>GMCNY</h1>   
                        </li>
                        <li className="date" date-date="2021">
                            <i className="fas fa-trophy"></i>
                            <h1>UNA</h1>   
                        </li>
                        <li className="date" date-date="2019">
                            <i className="fas fa-trophy"></i>
                            <h1>Sherpa cup</h1>   
                        </li>
                        <li className="date" date-date="2019">
                            <i className="fas fa-trophy"></i>
                            <h1>NHNC</h1>   
                        </li>
                        <li className="date" date-date="2019">
                            <i className="fas fa-trophy"></i>
                            <h1>GMCNY</h1>   
                        </li>
                        <li className="date" date-date="2018">
                            <i className="fas fa-trophy"></i>
                            <h1>Kapurthala Sports and Culture</h1>   
                        </li>
                        <li className="date" date-date="2018">
                            <i className="fas fa-trophy"></i>
                            <h1>GMCNY</h1>   
                        </li>
                        <li className="date" date-date="2017">
                            <i className="fas fa-trophy"></i>
                            <h1>NYNFC cup</h1>   
                        </li>
                        <li className="date" date-date="2016">
                            <i className="fas fa-trophy"></i>
                            <h1>NAC</h1>   
                        </li>
                        <li className="date" date-date="2016">
                            <i className="fas fa-trophy fa-trophy-silver"></i>
                            <h1>Aaroh Cup</h1>   
                        </li>
                        <li className="date" date-date="2016">
                            <i className="fas fa-trophy"></i>
                            <h1>Ambassador cup</h1>   
                        </li>
                        <li className="date" date-date="2015">
                            <i className="fas fa-trophy"></i>
                            <h1>Heartbeat</h1>
                            <small className="text-danger">Team B</small>
                        </li>

                        <li className="date" date-date="2015">
                            <i className="fas fa-trophy fa-trophy-silver"></i> 
                            <h1>Heartbeat</h1> 
                            <small className="text-danger">Team A</small>                 
                        </li>
                        <li className="date" date-date="2015">
                            <i className="fas fa-trophy"></i> 
                            <h1>Sherpa Cup</h1>
                        </li>
                        <li className="date" date-date="2015">
                            <i className="fas fa-trophy"></i> 
                            <h1>Help Nepal cup</h1>
                        </li>
                        <li className="date" date-date="2015">
                            <i className="fas fa-trophy fa-trophy-silver"></i> 
                            <h1>NYCSL</h1>
                        </li>
                        <li className="date" date-date="2014">
                            <i className="fas fa-trophy"></i> 
                            <h1>ANA</h1>
                        </li>
                        <li className="date" date-date="2012">
                            <i className="fas fa-trophy fa-trophy-silver"></i> 
                            <h1>Everest Cup</h1>
                        </li>
                        <li className="date" date-date="2011">
                            <i className="fas fa-trophy"></i> 
                            <h1>ANA</h1>
                        </li>
                        <li className="date" date-date="2011">
                            <i className="fas fa-trophy"></i> 
                            <h1>Everest cup</h1>
                        </li>
                        <li className="date" date-date="2011">
                            <i className="fas fa-trophy fa-trophy-silver"></i> 
                            <h1>ANA</h1>
                        </li>
                        <li className="date" date-date="2010">
                            <i className="fas fa-trophy"></i> 
                            <h1>Sherpa Cup</h1>
                        </li>
                        <li className="date" date-date="2010">
                            <i className="fas fa-trophy"></i> 
                            <h1>Indigenous cup</h1>
                        </li>
                        <li className="date" date-date="2009">
                            <i className="fas fa-trophy fa-trophy-silver"></i> 
                            <h1>Everest cup</h1>
                            <small className="text-danger">NYU</small>
                        </li>
                        <li className="date" date-date="2009">
                            <i className="fas fa-trophy"></i> 
                            <h1>NYNYC cup</h1>
                            <small className="text-danger">NYU</small>
                        </li>
                        <li className="date" date-date="2009">
                            <i className="fas fa-trophy"></i> 
                            <h1>Sherpa cup</h1>
                            <small className="text-danger">NYU</small>
                        </li>
                        <li className="date" date-date="2008">
                            <i className="fas fa-trophy"></i> 
                            <h1>Sherpa cup</h1>
                            <small className="text-danger">NYU</small>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Trophyhistory
