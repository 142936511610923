import React,{useEffect} from 'react'
import EditPlayer from './editplayer/EditPlayer.jsx'
import './editplayers.css'
import {useSelector} from 'react-redux'
import { Redirect } from 'react-router-dom'


const EditPlayers = () => {
     const players = useSelector(state => state.player.players)
     const user = useSelector(state=>state.auth.user)
     
    const playersArray = players !== null && players.map((player,index)=>{
        return <EditPlayer playerInfo={player} key={index} />
    })

    // if(!user){
    //     return <Redirect to="/login" />
    // }

    return (
        <div className="center-everything edit-players-container container">
            <h2 className="edit-players-heading">Players List</h2>
            {playersArray}
        </div>
    )
}

export default EditPlayers
