import {
    SET_PLAYERS,
    SET_PLAYERS_FAIL,
    SET_PLAYER,
    SET_PLAYER_FAIL,
    DELETE_PLAYER,
    DELETE_PLAYER_FAIL
} from '../actions/types'


const initialState = {
    players:[],
    player:{}
}

export default function(state=initialState, {type,payload}){
    switch(type){
        case SET_PLAYERS:
            return{...state, players:payload}
        case SET_PLAYER:
            return{...state, player:payload, players:[...state.players, payload]}
        case DELETE_PLAYER:
            return {
                ...state,
                players:state.players.filter(player=>player._id !== payload._id)
            }
        case SET_PLAYERS_FAIL:
        case DELETE_PLAYER_FAIL:
            return state
        case SET_PLAYER_FAIL:
            return {...state, player:{}}
        default:
            return state
    }
}