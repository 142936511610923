import React,{useEffect} from 'react'
import {Link, Redirect, Route} from 'react-router-dom'
import ExecutiveUpload from '../executives/ExecutiveUpload.jsx'
import PlayerUpload from '../players/PlayerUpload.jsx'
import {useSelector} from 'react-redux'
import TrainingSchedule from '../trainingschedule/TrainingSchedule.jsx'

import './adminnav.css'

const AdminNav = (props) => {
   const user = useSelector(state=>state.auth.user)
   const updateLink = (link)=>{
       if (link === 'player'){
        document.getElementById("player-active").classList.add("active-link")
        document.getElementById("executive-active").classList.remove("active-link")
        document.getElementById("training-active").classList.remove("active-link")
       } 
       if (link === 'executive'){
        document.getElementById("player-active").classList.remove("active-link")
        document.getElementById("executive-active").classList.add("active-link")
        document.getElementById("training-active").classList.remove("active-link")
       } 
       if(link === 'training'){
        document.getElementById("training-active").classList.add("active-link")
        document.getElementById("executive-active").classList.remove("active-link")
        document.getElementById("player-active").classList.remove("active-link")
       }
   }
   useEffect(()=>{
        user && updateLink(props.location.pathname.split("/").pop())
   },[])
   const handleonClick=(link)=>{
       updateLink(link)
   }
   if(!user){
      return <Redirect to="/" />
   }
   
    return (
        <div className="cotainer-fluid admin-nav-fluid">
            <div className="container admin-nav-container">
                <Link to="/admin/dashboard/player" onClick={()=>handleonClick("player")} id="player-active">Player</Link>
                <Link to="/admin/dashboard/executive" onClick={()=>handleonClick("executive")} id="executive-active">Executive</Link>
                <Link to="/admin/dashboard/training-schedule" onClick={()=>handleonClick("training")} id="training-active">Training</Link>
            </div>
            <div>
                <Route exact path="/admin/dashboard/player" component={PlayerUpload} />
                <Route exact path="/admin/dashboard/executive" component={ExecutiveUpload} />
                <Route exact path="/admin/dashboard/training-schedule" component={TrainingSchedule} />
            </div>
        </div>
    )
}

export default AdminNav
