import {
    SET_PUBLIC_PLAYERS,
    SET_PUBLIC_PLAYERS_FAIL,
    SET_PUBLIC_PLAYER,
    SET_PUBLIC_PLAYER_FAIL,
} from '../actions/types'

const initialState = {
    publicPlayers:[],
    publicPlayer:{}
}

export default function(state=initialState,{type, payload}){
    switch(type){
        case SET_PUBLIC_PLAYERS:
            return {...state, publicPlayers:payload}
        case SET_PUBLIC_PLAYER:
            return {...state, publicPlayer:state.publicPlayers.find(player=>player._id === payload)}
        case SET_PUBLIC_PLAYERS_FAIL:
            return {...state, publicPlayers:[]}
        case SET_PUBLIC_PLAYER_FAIL:
            return {...state, publicPlayer:{}}
        default:
            return state
    }
}