import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'

import './finance.css'
import FinanceForm from './FinanceForm.jsx'
import Transaction from './Transaction'

const Finance = () => {
    const user = useSelector(state=>state.auth.user)
   if(!user){
       return <Redirect to="/" />
   }
    return (
        <div className="finance-page">
            <div className="finance-image-container">
                <div className="finance-opacity">
                    <div className="container-fluid">
                        <div className="container finance-info-container">
                            <div className="balance">
                                <p>Available Balance | {new Date().getFullYear()}</p>
                                <h2>+ {5550}.00</h2>
                            </div>
                            <div className="income-info">
                                <p>Income</p>
                                <p>+{1580}.00</p>
                            </div>
                            <div className="expense-info">
                                <p>Expense</p>
                                <p>-{2000}.00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FinanceForm />
            <Transaction />
        </div>
    )
}

export default Finance
