import React from 'react'
import { Link } from 'react-router-dom'
import exeCardBg from './images/exe-card-bg.jpg'
import { useSelector } from 'react-redux'

import './executive.css'

function Executive() {
    const executives = useSelector(state => state.publicExecutives) 
    let mainExecutives = []
    let president = []
    let vicePresident = []
    let treasurer = []
    let clubAdvisior = []
    let manager = []
    let sponser = []

    executives.publicExecutives.length && executives.publicExecutives.forEach(executive => {
        if (executive.position === 'president') {
            president.push(executive)
        }
        if (executive.position === 'vice president') {
            vicePresident.push(executive)
        }
        if (executive.position === 'club advisior') {
            clubAdvisior.push(executive)
        }
        if (executive.position === 'manager') {
            manager.push(executive)
        }
        if (executive.position === 'Treasurer') {
           treasurer.push(executive)
        }
        if (executive.position === 'sponsor') {
            sponser.push(executive)
        }
    })
    mainExecutives = [...president, ...vicePresident, ...manager, ...clubAdvisior, ...sponser, ...treasurer].slice(0,6)
    const displayExecutives = executives.publicExecutives.length && mainExecutives.map((executive, idx) => {
        return (
            <div className="executive-card-wrapper" key={idx}>
                <div className="executive-card border-0">
                    <div className="executive-img-container">
                        <img src={exeCardBg} alt="" className="executive-img-bg" />
                        <img src={executive.imageUrl} alt="" className="card-img-top img-fluid mb-1 executive-img" />
                    </div>
                    <div className="card-body executive-card-body">
                        <h3 className="text-nowrap">{`${executive.firstName} ${executive.lastName}`}</h3>
                        <h5>{`${executive.position[0].toUpperCase()}${executive.position.slice(1)}`}</h5>
                        <div className="d-flex flex-row justify-content-center">
                            <div className="p-1">
                                <a href={executive.facebookUrl ? executive.facebookUrl : "#"}>
                                    <i className="fab fa-facebook fa-lg"></i>
                                </a>
                            </div>
                            <div className="p-1">
                                <a href={executive.instagramUrl ? executive.instagramUrl : "#"}>
                                    <i className="fab fa-instagram fa-lg"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <div className="container-fluid py-4 executive-bg">
            <div className="text-center executive-container">
                <div className="row mb-3">
                    <div className="col mb-4">
                        <h2 className="p-1 mb-1Name" id="executive-heading">Executive Members</h2>
                    </div>
                </div>
                <div className="executive-cards-wrapper mb-1">
                    {displayExecutives}
                </div>
            </div>
            <div className="row py-3">
                <div className="col text-center">
                    <Link to="/view-executives" alt="">
                        <button type="button" className="btn btn-outline  waves-effect many-more-button gallery-button">View More +</button>
                    </Link>
                    <br />
                </div>
            </div>
        </div>
    )
}

export default Executive
