import React, {useState} from 'react'


import pic1 from '../../img/nynfc/nynfc1.jpg'
import pic2 from '../../img/nynfc/nynfc2.jpg'
import pic3 from '../../img/nynfc/nynfc3.jpg'

const images = [pic1, pic2, pic3]

function Sponser(){

const [touchStart, setTouchStart] = useState(0)
const [touchEnd, setTouchEnd] = useState(0)

const handleTouchStart=(e)=>{
    setTouchStart(e.targetTouches[0].clientX)
}

const handleTouchMove = (e)=>{
    setTouchEnd(e.targetTouches[0].clientX)
}

const handleTouchEnd = (e) =>{
    if(touchStart-touchEnd > 20) {
        document.querySelector('.sponser-img').setAttribute("src", pic3)
        console.log("moved right")
    }

    if (touchStart-touchEnd < -20){
        document.querySelector('.sponser-img').setAttribute("src", pic1)
        console.log("moved left")
    }
}


    return(
        <div className="container-fluid text-center py-5 sponser-bg">

            {/* <div 
                className="square" 
                style={{height:"500px", width:"80%", margin:"auto", background:"blue"}}
                onTouchStart={(e)=>handleTouchStart(e)}
                onTouchMove={(e)=>handleTouchMove(e)}
                onTouchEnd={(e)=>handleTouchEnd(e)}
                >
                
                <img src={pic2} style={{width:"100%", height:"100%", objectFit:"cover"}} alt="" className="sponser-img" />

            </div> */}

            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-2 col-md-6 col-sm-10 offset-sm-1">
                        <a herf="#">
                            <h1 id="lama-acc-heading">Lama Accounting</h1>
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-10">
                        <a herf="#">
                            <h1 id="mustang-thakali-heading">Mustang Thakali Kitchen</h1>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sponser