import React,{useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { deletePlayer, updatePlayer } from '../../../../../actions/player'
import {Redirect} from 'react-router-dom'

const EditPlayer = ({playerInfo}) => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.auth.user)
    const [formData, setFormData] = useState({
        firstName:playerInfo.firstName,
        lastName:playerInfo.lastName,
        email:playerInfo.email,
        age:playerInfo.age,
        phone:playerInfo.phone,
        reside:playerInfo.reside,
        feet:Math.floor(playerInfo.height/1),
        inch:Math.ceil((playerInfo.height%1)*10),
        position:playerInfo.position,
        team:playerInfo.team,
        membership:playerInfo.membership,
        executive:playerInfo.executive,
        facebookUrl:playerInfo.facebookUrl,
        instagramUrl:playerInfo.instagramUrl,
        linkedInUrl:playerInfo.linkedInUrl,
        webUrl:playerInfo.webUrl,
    })


    let {firstName,lastName,email,age,phone,reside,feet,inch,position,membership,executive,facebookUrl,instagramUrl,linkedInUrl,webUrl} = formData
    const [fileData, setFileData] = useState({
        playerImg:''
    })
    let {playerImg} = fileData
    let fileName = "Player Image"
    if(playerImg){
        fileName=playerImg.name
    }

    const updatePreviewImage=(e)=>{
        let imgEle = document.getElementById('preview-img')
        let file = e.target.files[0]
        if (file.type === "image/png" || "image/jpg" || "image/jpeg"){
            const reader = new FileReader()
            reader.onloadend=function(){
                imgEle.src = reader.result
            }
            reader.readAsDataURL(file)
        }
    }
    const onChange=(e)=>setFormData({...formData, [e.target.name]:e.target.value})
    const onChangeFile=(e)=>{
        setFileData({...fileData, [e.target.name]:e.target.files[0]})
        updatePreviewImage(e)
    }

    const handleonSubmit=(e)=>{
        e.preventDefault()
        const imageData = new FormData()
        imageData.append('playerImg', playerImg)
        imageData.append('firstName', firstName)
        imageData.append('lastName', lastName)
        imageData.append('email', email)
        imageData.append('age', age)
        imageData.append('phone', phone)
        imageData.append('reside', reside)
        imageData.append('height', `${feet}.${inch}`)
        imageData.append('position', position)
        imageData.append('membership',membership)
        imageData.append('executive',executive)
        imageData.append('facebookUrl',facebookUrl)
        imageData.append('instagramUrl', instagramUrl)
        imageData.append('linkedInUrl', linkedInUrl)
        imageData.append('webUrl',webUrl)
        dispatch(updatePlayer(imageData,playerInfo._id))
    }

    const handleDelete = (id) =>{
        if(window.confirm("Are you sure want to delete this player?")) dispatch(deletePlayer(id))
    }

    // if(!user) {
    //     return <Redirect to="/login" />
    // }

    return (
        <div className="card accordion-card">
           <div className="card-header" id="headingOne">
                <div className="mb-0 accordion-pannel">
                    <h5>{playerInfo.firstName[0].toUpperCase()+playerInfo.firstName.slice(1).toLowerCase()
                    +" "+playerInfo.lastName[0].toUpperCase()+playerInfo.lastName.slice(1).toLowerCase()}
                    </h5>
                    <div className="accordion-btn-group">
                        <button className="btn btn-warning accordion-edit-btn mr-1" 
                        data-toggle="collapse" 
                        data-target={`#collapse${playerInfo._id}`} 
                        aria-expanded="true" 
                        aria-controls={`collapse${playerInfo._id}`}>
                            Edit
                        </button>
                        <button className="btn btn-danger" onClick={()=>handleDelete(playerInfo._id)}>
                            Delete
                        </button>
                    </div>
                </div>
           </div>
            <div id={`collapse${playerInfo._id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body" style={{padding:"8px"}}>
                <form onSubmit={e=>handleonSubmit(e)} className="player-upload-form player-upload-form-custom">
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="First name"
                                name="firstName"
                                value={firstName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Last name"
                                name="lastName"
                                value={lastName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="email"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={email}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Phone number"
                                name="phone"
                                value={phone}
                                pattern=".{10,10}"
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Reside In"
                                name="reside"
                                value={reside}
                            />
                        </div>
                        <div className="form-group-x">
                            <label htmlFor="ageId">Age:</label>
                            <input
                                type="number"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Age"
                                name="age"
                                value={age}
                                min="10"
                                max="100"
                                id="ageId"
                            />
                        </div>
                        <div className="form-group-x">
                            <label htmlFor="heightId">Height:</label>
                            <div className="height-info">
                                <input
                                    type="number"
                                    onChange={e=>onChange(e)}
                                    className="form-control"
                                    placeholder="ft"
                                    name="feet"
                                    value={feet}
                                    min="1"
                                    max="10"
                                    id="heightId"
                                />
                                <p>ft</p>
                                <input
                                    type="number"
                                    onChange={e=>onChange(e)}
                                    className="form-control"
                                    placeholder="inch"
                                    name="inch"
                                    value={inch}
                                    min="1"
                                    max="11"
                                />
                                <p>inch</p>
                        </div>
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="position" 
                                value={position} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Position?</option>
                                <option value="goalkeeper">GoalKeeper</option>
                                <option value="defender">Defender</option>
                                <option value="fullback">Fullback</option>
                                <option value="centerback">Centerback</option>
                                <option value="midfielder">Midfielder</option>
                                <option value="winger">Winger</option>
                                <option value="striker">Striker</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="membership" 
                                value={membership} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Membership paid?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <select className="form-control" 
                                name="executive" 
                                value={executive} 
                                onChange={e=>onChange(e)}
                                >
                                <option disabled value="">Is Executive?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="facebook Url - Optional"
                                name="facebookUrl"
                                value={facebookUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="instagram Url - Optional"
                                name="instagramUrl"
                                value={instagramUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="LinkedIn Url - Optional"
                                name="linkedInUrl"
                                value={linkedInUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Web Url - Optional"
                                name="webUrl"
                                value={webUrl}
                            />
                        </div>

                        <div className="custom-file">
                        <input 
                            type="file" 
                            name="playerImg" 
                            onChange={e=>onChangeFile(e)}
                            className="custom-file-input" 
                            id="customFile" 
                            />
                            <label className="custom-file-label"  htmlFor="customFile">{fileName}</label>
                        </div>
                        <div className="image-preview-wrapper">
                            <img src={playerInfo.imageUrl} alt="profile-img" className="preview-img" id="preview-img"/>
                        </div>
                        <div className="center-button">
                            <button type='Submit' className='btn btn-primary my-4 px-5 player-upload-button'>UPDATE</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditPlayer
