import axios from 'axios'
import {
    SET_PUBLIC_EXECUTIVES,
    SET_PUBLIC_EXECUTIVES_FAIL
} from './types'

export const getPublicExecutives = () => async dispatch=>{
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/executive/public/executives')
        dispatch({
            type:SET_PUBLIC_EXECUTIVES,
            payload:data.executives
        })
    } catch (err) {
        dispatch({
            type:SET_PUBLIC_EXECUTIVES_FAIL
        })
    }
}