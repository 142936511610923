import React,{useEffect} from 'react'
import ProduceGallery from './ProduceGallery'

import nynfc1 from './../../img/nynfc/nynfc1.jpg'
import nynfc2 from './../../img/nynfc/nynfc2.jpg'
import nynfc3 from './../../img/nynfc/nynfc3.jpg'
import nynfc4 from './../../img/nynfc/nynfc4.jpg'
import nynfc5 from './../../img/nynfc/nynfc5.jpg'
import nynfc6 from './../../img/nynfc/nynfc6.jpg'
import nynfc7 from './../../img/nynfc/nynfc7.jpg'
import nynfc8 from './../../img/nynfc/nynfc8.jpg'
import nynfc9 from './../../img/nynfc/nynfc9.jpg'
import nynfc10 from './../../img/nynfc/nynfc10.jpg'
import nynfc11 from './../../img/nynfc/nynfc11.jpg'
import nynfc12 from './../../img/nynfc/nynfc12.jpg'


import './fullgallery.css'


const images = [nynfc1,nynfc2,nynfc3,nynfc4,nynfc5,nynfc6,nynfc7,nynfc8,nynfc9,nynfc10,nynfc11,nynfc12]
let heading = 'NYNFC gallery'

const Fullgallery = () => {
    return(
        <ProduceGallery data={{images:images,heading:heading}} />
    )
}

export default Fullgallery