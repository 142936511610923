import axios from 'axios'
import React,{useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {addExecutive, getExecutives} from '../../../actions/executive'
import EditExecutives from './editExecutives/EditExecutives'

import './executiveupload.css'

const ExecutiveUpload = () => {

const dispatch = useDispatch()
useEffect(()=>{
    dispatch(getExecutives())
},[dispatch])

const [formData, setFormData] = useState({
    firstName:'',
    lastName:'',
    email:'',
    number:'',
    reside:'',
    position:'',
    membership:'',
    facebookUrl:'',
    instagramUrl:'',
    linkedInUrl:'',
    webUrl:'',
})

let {firstName,lastName,email,number,reside,position,membership,facebookUrl,instagramUrl,linkedInUrl,webUrl} = formData

const [fileData, setFileData] = useState({
    executiveImg:''
})

let {executiveImg} = fileData

const onChange=(e)=>setFormData({...formData, [e.target.name]:e.target.value})
const onChangeFile=(e)=>setFileData({...fileData, [e.target.name]:e.target.files[0]})


let fileName = "executive Image"
if(executiveImg){
    fileName=executiveImg.name
}

const handleonSubmit=async e =>{
    e.preventDefault()
    const imageData = new FormData()
    imageData.append('executiveImg', executiveImg)
    imageData.append('firstName',firstName)
    imageData.append('lastName', lastName)
    imageData.append('number', number)
    imageData.append('email', email)
    imageData.append('reside', reside)
    imageData.append('position', position)
    imageData.append('membership',membership)
    imageData.append('facebookUrl',facebookUrl)
    imageData.append('instagramUrl', instagramUrl)
    imageData.append('linkedInUrl', linkedInUrl)
    imageData.append('webUrl',webUrl)

    let res = dispatch(addExecutive(imageData))

    if(res){
        setFormData({
            firstName:'',
            lastName:'',
            email:'',
            number:'',
            reside:'',
            position:'',
            membership:'',
            facebookUrl:'',
            instagramUrl:'',
            linkedInUrl:'',
            webUrl:'',
        })
        setFileData({
            executiveImg:''
        })
    }

}
    return (
        <div className="container-fluid executive-upload-fluid">
            <div className="container executive-upload-container">
                <div className="row">
                    <form className="executive-upload-form" onSubmit={e=>handleonSubmit(e)}>
                        <div className="executive-upload-heading-bg">
                            <h1 className="executive-upload-heading">Add New Member</h1>
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="First name"
                                name="firstName"
                                value={firstName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Last name"
                                name="lastName"
                                value={lastName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="email"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={email}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Phone number"
                                name="number"
                                value={number}
                                pattern=".{10,10}"
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Reside In"
                                name="reside"
                                value={reside}
                            />
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="position" 
                                value={position} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Member Type?</option>
                                <option value="president">President</option>
                                <option value="vice president">Vice President</option>
                                <option value="club advisior">Club advisior</option>
                                <option value="manager">Manager</option>
                                <option value="Treasurer">Treasurer</option>
                                <option value="sponsor">Club Sponsor</option>
                                <option value="member">Member</option>
                            </select>
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="membership" 
                                value={membership} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Membership paid?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="facebook Url - Optional"
                                name="facebookUrl"
                                value={facebookUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="instagram Url - Optional"
                                name="instagramUrl"
                                value={instagramUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="LinkedIn Url - Optional"
                                name="linkedInUrl"
                                value={linkedInUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Web Url - Optional"
                                name="webUrl"
                                value={webUrl}
                            />
                        </div>

                        <div className="custom-file">
                        <input 
                            type="file" 
                            name="executiveImg" 
                            onChange={e=>onChangeFile(e)}
                            className="custom-file-input" 
                            id="customFile" 
                            required
                            />
                            <label className="custom-file-label"  htmlFor="customFile">{fileName}</label>
                        </div>
                        <div className="center-button">
                            <button type='Submit' className='btn btn-primary my-4 px-5 executive-upload-button'>Submit</button>
                        </div>
                    </form>
                </div>
                <div>
                    <EditExecutives />
                </div>
            </div>
        </div>
    )
}

export default ExecutiveUpload
