import {combineReducers} from 'redux'
import alert from './alert'
import auth from './auth'
import player from './player'
import executive from './executive'
import training from './training'
import publicPlayers from './publicPlayers'
import publicExecutives from './publicExecutives'
import publicTraining from './publicTraining'

export default combineReducers({
alert,
auth,
player,
executive,
training,
publicPlayers,
publicExecutives,
publicTraining
})