import React,{useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Redirect } from 'react-router-dom'
import {updateExecutive,deleteExecutive} from '../../../../../actions/executive'

const EditExecutive = ({executiveInfo}) => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.auth.user)

    const [formData, setFormData] = useState({
        firstName:executiveInfo.firstName,
        lastName:executiveInfo.lastName,
        email:executiveInfo.email,
        number:executiveInfo.phone,
        reside:executiveInfo.reside,
        position:executiveInfo.position,
        membership:executiveInfo.membership,
        facebookUrl:executiveInfo.facebookUrl,
        instagramUrl:executiveInfo.instagramUrl,
        linkedInUrl:executiveInfo.linkedInUrl,
        webUrl:executiveInfo.webUrl,
    })
    let {firstName,lastName,email,number,reside,position,membership,facebookUrl,instagramUrl,linkedInUrl,webUrl} = formData
    const [fileData, setFileData] = useState({
        executiveImg:''
    })
    let {executiveImg} = fileData

    let fileName = "executive Image"
    if(executiveImg){
        fileName=executiveImg.name
    }

    const updatePreviewImage=(e)=>{
        let imgEle = document.getElementById('executive-preview-img')
        let file = e.target.files[0]
        if (file.type === "image/png" || "image/jpg" || "image/jpeg"){
            const reader = new FileReader()
            reader.onloadend=function(){
                imgEle.src = reader.result
            }
            reader.readAsDataURL(file)
        }
    }
    
    const onChange=(e)=>setFormData({...formData, [e.target.name]:e.target.value})
    const onChangeFile=(e)=>{
        setFileData({...fileData, [e.target.name]:e.target.files[0]})
        updatePreviewImage(e)
    }

    const handleDelete = (id)=>{
        if(window.confirm("Are you sure want to delete this Executive")) dispatch(deleteExecutive(executiveInfo._id))
    }
    const handleonSubmit =(e)=>{
        e.preventDefault()
 
        const imageData = new FormData()
        imageData.append('executiveImg', executiveImg)
        imageData.append('firstName',firstName)
        imageData.append('lastName', lastName)
        imageData.append('number', number)
        imageData.append('email', email)
        imageData.append('reside', reside)
        imageData.append('position', position)
        imageData.append('membership',membership)
        imageData.append('facebookUrl',facebookUrl)
        imageData.append('instagramUrl', instagramUrl)
        imageData.append('linkedInUrl', linkedInUrl)
        imageData.append('webUrl',webUrl)
        
        dispatch(updateExecutive(imageData, executiveInfo._id))
    }

    // if(!user){
    //     return <Redirect to="/login" />
    // }

    return (
        <div className="card accordion-card">
           <div className="card-header" id="headingOne">
                <div className="mb-0 accordion-pannel">
                        <h5>{executiveInfo.firstName[0].toUpperCase()+executiveInfo.firstName.slice(1).toLowerCase()
                        +" "+executiveInfo.lastName[0].toUpperCase()+executiveInfo.lastName.slice(1).toLowerCase()}
                        </h5>
                        <div className="accordion-btn-group">
                            <button className="btn btn-warning accordion-edit-btn mr-1" 
                            data-toggle="collapse" 
                            data-target={`#collapse${executiveInfo._id}`} 
                            aria-expanded="true" 
                            aria-controls={`collapse${executiveInfo._id}`}>
                                Edit
                            </button>
                            <button className="btn btn-danger" onClick={()=>handleDelete(executiveInfo._id)}>
                                Delete
                            </button>
                        </div>
                </div>
            </div>

            <div id={`collapse${executiveInfo._id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body" style={{padding:"8px"}}>
                    <form onSubmit={e=>handleonSubmit(e)} className="executive-upload-form executive-upload-form-custom">
                    <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="First name"
                                name="firstName"
                                value={firstName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Last name"
                                name="lastName"
                                value={lastName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="email"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={email}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Phone number"
                                name="number"
                                value={number}
                                pattern=".{10,10}"
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Reside In"
                                name="reside"
                                value={reside}
                            />
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="position" 
                                value={position} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Member Type?</option>
                                <option value="president">President</option>
                                <option value="vice president">Vice President</option>
                                <option value="club advisior">Club advisior</option>
                                <option value="manager">Manager</option>
                                <option value="Treasurer">Treasurer</option>
                                <option value="sponsor">Club Sponsor</option>
                                <option value="member">Member</option>
                            </select>
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="membership" 
                                value={membership} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Membership paid?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="facebook Url - Optional"
                                name="facebookUrl"
                                value={facebookUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="instagram Url - Optional"
                                name="instagramUrl"
                                value={instagramUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="LinkedIn Url - Optional"
                                name="linkedInUrl"
                                value={linkedInUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Web Url - Optional"
                                name="webUrl"
                                value={webUrl}
                            />
                        </div>

                        <div className="custom-file">
                        <input 
                            type="file" 
                            name="executiveImg" 
                            onChange={e=>onChangeFile(e)}
                            className="custom-file-input" 
                            id="customFile" 
                            />
                            <label className="custom-file-label"  htmlFor="customFile">{fileName}</label>
                        </div>
                        <div className="image-preview-wrapper">
                            <img src={executiveInfo.imageUrl} alt="profile-img" className="preview-img" id="executive-preview-img"/>
                        </div>
                        <div className="center-button">
                            <button type='Submit' className='btn btn-primary my-4 px-5 executive-upload-button'>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditExecutive
