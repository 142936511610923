import React from 'react'
import './captain.css'

const CaptainIcon = ({color="yellow",width="30px",fontSize="18px"}) => {
  return (
    <div className='captain-icon' style={{color,width,fontSize}}>C</div>
  )
}

export default CaptainIcon
