import React, {Fragment}from 'react'
import {Link, Redirect} from 'react-router-dom'
import logo from './../../img/nynfc_logo.svg'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {logout} from './../../actions/auth'

function  Navbar({auth:{isAuthenticated,loading,user}, logout}) {
function scrollToDiv(divId){
    setTimeout(() => {
        if (document.getElementById(divId) == null ){
            return (<Redirect to='/' />)
        } else {
            window.scroll({
                top:document.getElementById(divId).offsetTop,
                left:0,  
                behavior: 'smooth' 
            });
        }
    }, 100);
}
const authLinks=(
    <ul className="navbar-nav ml-auto">
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("slider")}>Home</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("about-us")}>About us</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("team")}>Players</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("executive")}>Members</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("contact")}>Contact</Link>
        </li>
        {/* <li className="nav-item"  data-toggle="collapse" data-target="#navbarResponsive" className="rounded-circle border-danger">
            <Link className="nav-link" to="/"><i className="fas fa-user fa-lg text-success"></i></Link>
        </li> */}
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/admin/dashboard/player">Dashboard</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/finance">Finance</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive" onClick={logout}>
            <Link className="nav-link" to="/">Logout</Link>
        </li>
        
    </ul>
)

const guestLinks=(
    <div className="navbar-nav ml-auto">
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("slider")}>Home</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("about-us")}>About us</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("team")}>Players</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("executive")}>Members</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/" onClick={()=>scrollToDiv("contact")}>Contact</Link>
        </li>
        {/* <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/register">Register</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <Link className="nav-link" to="/login"></Link>
        </li> */}
    </div>
)
    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
            <Link className="navbar-brand" to="/">
                <img src={logo} alt="nynfc logo" id="nav-bar-logo"/>
            </Link>
            <Link className="navbar-brand" to="/" id="nynfc-text">NYNFC</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" style={{borderColor:"white"}}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
            </div>
        </nav>
    )
}
Navbar.propTypes = {
auth:PropTypes.object.isRequired,
logout:PropTypes.func.isRequired
}

const mapStateToProps = state =>({
    auth:state.auth,
})

export default connect(mapStateToProps,{logout})(Navbar)
