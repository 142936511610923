import axios from 'axios'
import {setAlert} from './alert'
import {REGISTER_SUCCESS, REGISTER_FAIL,USER_LOADED,AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL,LOGOUT } from './types'
import setAuthToken from './../utils/setAuthToken'
import toast from 'react-hot-toast'

//Load user
export const loadUser = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token)
    }
    try {
        const res = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/auth')
        dispatch({
            type:USER_LOADED,
            payload:res.data
        })
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}
// Register user 
export const register = ({name,email,password})=> async dispatch=>{
    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    const body = JSON.stringify({name,email,password})
    try {
        const res = await axios.post('https://erudite-scarab-311921.uc.r.appspot.com/api/users',body,config)
        dispatch({
            type:REGISTER_SUCCESS,
            payload:res.data
        })
        toast.success("Thank you for registering.")
        dispatch(loadUser())
    } catch (err) {
        const errors = err.response.data.errors
        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type:REGISTER_FAIL
        })
        toast.error("Registration fail!")
    }
}

// Login user 

export const login = (email,password)=> async dispatch=>{
    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    const body = JSON.stringify({email,password})
    try {
        const res = await axios.post('https://erudite-scarab-311921.uc.r.appspot.com/api/auth',body,config)
        dispatch({
            type:LOGIN_SUCCESS,
            payload:res.data
        })
        dispatch(loadUser())
    } catch (err) {
        const errors = err.response.data.errors
        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type:LOGIN_FAIL
        })
        toast.error("Something went wrong!")
    }
}

// Logout / Clear profile 
export const logout = () => dispatch => {
    dispatch({type:LOGOUT})
}
