import React from 'react'
import './mousescrollbtn.css'

const MouseScrollBtn = () => {
  return (
    <div id="scroll-mouse-btn"></div>
  )
}

export default MouseScrollBtn
