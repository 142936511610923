import React,{useEffect, useState} from 'react'
import '../../styles/gallery.css'
import {handleonClick,nextImage,prevImage,hideModal} from './actions/galleryActions'

import './fullgallery.css'

const ProduceGallery=(props)=>{

    const [touchStart, setTouchStart] = useState(0)
    const [touchEnd, setTouchEnd] = useState(0)

    useEffect(()=>{
        window.addEventListener("keydown",(e)=>{
            switch(e.key){
                case "ArrowRight":
                    return nextImage(images)
                case "ArrowLeft":
                    return prevImage(images)
                case "Escape":
                    return document.querySelector('.modal').style.display="none"
                default:
                    return
            }
        })
        },[])
        let images = props.data.images



    const handleTouchStart=(e)=>{
        setTouchStart(e.targetTouches[0].clientX)
    }

    const handleTouchMove = (e)=>{
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const handleTouchEnd = (e) =>{
        if(touchStart-touchEnd > 20) {
            nextImage(images)
        }

        if (touchStart-touchEnd < -20){
            prevImage(images)
        }
    }

    return(
        <div className="gallery-section">
            <div className="inner-width">
                {props.data.heading && 
                    <div>
                        <h1>NYNFC gallery</h1>
                        <div className="border"></div>
                    </div>}
                <div className="produce-gallery-img-wrapper">
                    {images.map((image,index)=>(
                    <div className="produce-gallery-img-div" key={index} onClick={()=>(handleonClick(index,images))}>
                        <img 
                        src={image} 
                        alt="" 
                        className="produce-gallery-img" 
                        />
                    </div>))}
                </div>
            </div>
            <div className="modal" onClick={(e)=>(hideModal(e))}>
                  <div className="modal-img-container">
                      <img src="" 
                      className="img-fluid-custom" 
                      alt="" 
                      data-index=""
                      onTouchStart={(e)=>handleTouchStart(e)}
                      onTouchMove={(e)=>handleTouchMove(e)}
                      onTouchEnd={(e)=>handleTouchEnd(images)}
                      />

                      {/* <button type="button" className="btn button-left" onClick={()=>(prevImage(images))}>
                      <i className="fas fa-arrow-left fa-2x"></i>
                      </button>
                      <button type="button" className="btn button-right" onClick={()=>(nextImage(images))}>
                        <i className="fas fa-arrow-right fa-2x"></i>
                      </button>
                      <button type="button" className="btn button-top" onClick={()=>(document.querySelector('.modal').style.display="none")}>
                        <i className="fas fa-times fa-2x"></i>
                      </button> */}
                  </div>
            </div>
        </div>
    )
}
export default  ProduceGallery
