import { SET_TRAINING,SET_TRAINING_FAIL } from "../actions/types";

const initialState = {
    training:{}
}

export default function(state=initialState, {type,payload}){
    switch(type){
        case SET_TRAINING:
            return {...state,training:payload}
        case SET_TRAINING_FAIL:
            return state
        default:
            return state
    }
}