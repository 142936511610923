import React,{Fragment} from 'react'
import '../../styles/mainlandingpage.css'

 function MainLandingPage() {
    return (
        <Fragment>
            <div className="image-container">
                <div className="opacity">
                    {/* <div className="nynfc-h1">
                        <div className="white-border">
                            <div className="blue-border">
                                <p>Welcome to</p>
                                <h1>NYNFC</h1>
                                <i className="fas fa-futbol fa-lg" style={{marginRight:"3px",color:"red"}}></i>
                                <i className="fas fa-futbol fa-lg" style={{marginRight:"3px"}}></i>
                                <i className="fas fa-futbol fa-lg" style={{color:"blue"}}></i>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </Fragment>
    )
}
export default MainLandingPage