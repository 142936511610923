import React,{Fragment} from 'react'

import Contact from './Contact/Contact'
import Sponser from './Sponser'
import History from './History/History.jsx'
import Team from './Team/Team.jsx'
import Trophy from './Trophy/Trophy.jsx'
import Gallery from '../gallery/Gallery/Gallery.jsx'
import Executive from './Executive/Executive.jsx'
import Trainingsession from './Trainingsession/Trainingsession.jsx'
import News from './News/News.jsx'
import MainLandingPage from './MainLandingPage'
import About from './About/About'
import Mainview from './Mainview/Mainview'

function Landing() {
    return (
        
        <Fragment>
            <div id="slider">
                <MainLandingPage />
            </div>
            <div id="mainview">
                <Mainview />
            </div>
            <div id="about-us">
                <About />
            </div>
            <div id="training-session">
                <Trainingsession />
            </div>
            <div id="history">
                <History />
            </div>
            <div id="executive">
                <Executive />
            </div>
            <div id="team">
                <Team />
            </div>
            <div id="trophy">
                <Trophy />
            </div>
            <div id="gallery">
                <Gallery />
            </div>
            <div id="news">
                <News />
            </div>
            <div id="contact">
                <Contact />
            </div>  
            <Sponser />

        </Fragment>
        
    )
}

export default Landing
