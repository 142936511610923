import axios from 'axios'
import toast from 'react-hot-toast'
import {
    SET_TRAINING,
    SET_TRAINING_FAIL
} from './types'


export const getTraining = () => async dispatch =>{
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/training')
        dispatch({
            type:SET_TRAINING,
            payload:data.trainings[0]
        })
    } catch (err) {
        dispatch({
            type:SET_TRAINING_FAIL
        })
    }
}

export const updateTraining = (id, formData) => async dispatch =>{
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    }
    let data = JSON.stringify(formData)
    try {
        let res = await axios.post(`https://erudite-scarab-311921.uc.r.appspot.com/api/training/${id}`,data,config)
        if(res.data){
            dispatch({
                type:SET_TRAINING,
                payload:res.data.training
            })
            toast.success("Successfully updated!")
        }
        dispatch({
            type:SET_TRAINING_FAIL
        })
    } catch (err) {
        dispatch({
            type:SET_TRAINING_FAIL
        })
        toast.error('Something went wrong!')
    }
}
