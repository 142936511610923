import axios from 'axios'
import toast from 'react-hot-toast'
import {
    SET_EXECUTIVE,
    SET_EXECUTIVE_FAIL,
    SET_EXECUTIVES_FAIL,
    SET_EXECUTIVES,
    DELETE_EXECUTIVE,
    DELETE_EXECUTIVE_FAIL
} from './types'


export const addExecutive = (imageData) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'multipart/form-data'
        }
    }
    try {
        let {data} = await axios.post('https://erudite-scarab-311921.uc.r.appspot.com/api/executive', imageData,config)
        if(data.executive){
            dispatch({
                type:SET_EXECUTIVE,
                payload:data.executive
            })
            toast.success("Successfully added!")
            return true
        }
    } catch (err) {
        dispatch({
            type:SET_EXECUTIVE_FAIL
        })
        toast.error("Something went wrong!")
    }
}

export const getExecutives = () => async dispatch => {
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/executive')
        dispatch({
            type:SET_EXECUTIVES,
            payload:data.executives
        })
    } catch (err) {
        dispatch({
            type:SET_EXECUTIVES_FAIL
        })
    }
}

export const updateExecutive = (imageData, id) => async dispatch=>{
    const config = {
        headers:{
            'Content-type':'multipart/form-data'
        }
    }
    try {
        let updateExecutive = await axios.post(`https://erudite-scarab-311921.uc.r.appspot.com/api/executive/${id}`,imageData,config)
        if(updateExecutive){
            toast.success("Successfully updated!")
        }
    } catch (err) {
        toast.success("Something went wrong!")
        console.log(err)
    }
}

export const deleteExecutive = (id) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'application/json'
        }
    }
    const body = JSON.stringify({id:id})
    try {
        let {data} = await axios.delete(`https://erudite-scarab-311921.uc.r.appspot.com/api/executive/${id}`,body,config)
        if(data){
           dispatch({
               type:DELETE_EXECUTIVE,
               payload:data.executive
           })
           toast.success("Successfully deleted!")
        }
    } catch (err) {
        dispatch({
            type:DELETE_EXECUTIVE_FAIL,
        })
        toast.error("Something went wrong!")
    }
}
