import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import nynfc1 from './images//nynfc1.jpg'
import nynfc2 from './images//nynfc2.jpg'
import nynfc3 from './images//nynfc3.jpg'
import nynfc4 from './images//nynfc4.jpg'

import ProduceGallery from '../ProduceGallery'
import './gallery.css'

let images = [nynfc1,nynfc2,nynfc3,nynfc4]
function Gallery(){
    return(
        <div className="container-fluid py-3 gallery-fluid">
               <div className="gallery-container text-center">
                    <div className="row mb-3">
                            <div className="col mb-4">
                            <h2 className="p-2 mb-1Name" id="gallery-heading">Team Photos</h2>
                            </div>
                    </div>
               </div>
               <ProduceGallery data={{images:images}} />
               <div className="row mt-4">
                            <div className="col text-center">
                                <Link to="/view-gallery" alt=""><button type="button" className="btn btn-outline waves-effect many-more-button gallery-button">View More +</button></Link>
                                <br />
                            </div>
                        </div>
                 </div>
    )
}

export default Gallery