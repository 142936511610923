import axios from 'axios'
import {
    SET_PUBLIC_PLAYERS,
    SET_PUBLIC_PLAYERS_FAIL,
    SET_PUBLIC_PLAYER,
    SET_PUBLIC_PLAYER_FAIL,
} from './types'

export const getPublicPlayer = (id) => async dispatch=>{
    try {
        dispatch({
            type:SET_PUBLIC_PLAYER,
            payload:id
        })
    } catch (error) {
        dispatch({
            type:SET_PUBLIC_PLAYER_FAIL
        })
    }
}


export const getPublicPlayers = () => async dispatch=>{
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/player/public/players')
        dispatch({
            type:SET_PUBLIC_PLAYERS,
            payload:data.players
        })
    } catch (error) {
        dispatch({
            type:SET_PUBLIC_PLAYERS_FAIL
        })
    }
}

