import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

const Alert = ({alerts}) => alerts !== null && alerts.length > 0 && alerts.map(alert=>(
    <div className={`container-fluid text-center p-2 bg-${alert.alertType}`} key={alert.id} style={{color:"white"}}>
        <h3>{alert.msg}</h3>
    </div>
))

Alert.PropTypes = {
alerts:PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    alerts:state.alert
})

export default connect(mapStateToProps)(Alert)