import React,{Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import CaptainIcon from '../icons/CaptainIcon.jsx'

import soccer from '../layout/Team/images/soccer.png'
import nynfcLogo from './../../img/nynfc_logo.svg'
import {getPublicPlayer} from '../../actions/publicPlayer'

const PlayersLanding = () => {
    const players = useSelector(state=>state.publicPlayers)
    const dispatch = useDispatch()
    const handleonClick = (id) =>{
        dispatch(getPublicPlayer(id))
    }

    const displayPlayers = players.publicPlayers.length && players.publicPlayers.map((player, idx)=>{
        return (
            <div className="team-card border-0" key={idx}> 
                <div className="team-card-inner">
                    <Link to={`/player-profile/${player._id}`} onClick={()=>handleonClick(player._id)}>
                        <img src={player.imageUrl} alt="" className="card-img-top img-fluid mb-1" />  
                    </Link>
                    {player.firstName.toLowerCase() === 'swasthik' && (
                        <div className='card-captain-icon'>
                            <CaptainIcon />
                        </div> 
                    )}
                </div>
                <div className="team-card-body">
                    <Link to={`/player-profile/${player._id}`} onClick={()=>handleonClick(player._id)}>
                        <h4 className="team-card-name">{`${player.firstName} ${player.lastName}`}</h4>  
                        <h6>{`${player.position[0].toUpperCase()}${player.position.slice(1)}`}</h6> 
                        {/* <h2 className="text-danger player-number">NYNFC</h2> */}
                    </Link>
                    <div className="d-flex flex-row justify-content-center pt-1">
                        <div className="px-1">
                            <a href={player.facebookUrl ? player.facebookUrl : "#"}>
                                <i className="fab fa-facebook fa-lg"></i>
                            </a>
                        </div>
                        <div className="px-1">
                            <a href={player.instagramUrl ? player.instagramUrl : "#"}>
                                <i className="fab fa-instagram fa-lg"></i>
                            </a>
                        </div>
                    </div>              
                </div>
            </div>
        )
    })

    return(
        <div className="same-background-color" style={{background:"#003366"}}>
             <div className="container-fluid players-landing-container-main text-center">
                <div className="players-landing-img-h1">
                    <img src={soccer} alt="" className="players-landing-img" />
                        <h1 className="player-landing-h1">View our Players</h1>
                        <img src={nynfcLogo} alt="" className="players-landing-nynfc-img" />
                </div>
            </div>
            <div className="container-fluid bg team-fluid" id="team" style={{borderTop:"none"}}>
                <div className="team-container py-4 text-center" >
                    <div className="team-card-wrapper">
                        {displayPlayers}
                    </div>
                </div>
            </div>
        </div>
            
    )
}

export default PlayersLanding


