import React from 'react'
import './contact.css'

function Contact(){
    return(
            <div className="container-fluid text-center contact-us-fluid">
                <div className="row">
                    <div className="col">
                        <h1 id="contact-us-title">Contact Us</h1>
                    </div>
                </div>

                <div className="container contact-smedia-container">
                        <div className="">
                            <a href="https://www.facebook.com/groups/nynfc/">
                                <i className="fab fa-facebook fa-2x contact-icon-fb con-icon"></i>
                            </a>
                        </div>
                        <div className="">
                            <a href='https://www.instagram.com/explore/tags/nynfc/?hl=en'>
                                <i className="fab fa-instagram fa-2x contact-icon-in con-icon"></i>
                            </a>
                        </div>
                        {/* <div className="">
                            <a onClick={() => window.location = 'http://google.com'}>
                                <i className="fas fa-phone fa-2x contact-icon-phone con-icon"></i>
                            </a>
                        </div> */}
                </div>

                <div className="container contact-us-container">
                    <div className="contact-us-map">
                        <iframe 
                        title="randalls-map"
                         className="contact-us-google-map"
                         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.9828444621344!2d-73.92479270476305!3d40.795744430826105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f5ef93db8461%3A0xdfa7f56c4c30b6ee!2sRFK%20Bridge%20TBTA%20Building!5e0!3m2!1sen!2sus!4v1578954322080!5m2!1sen!2sus" 
                         frameBorder="0" 
                         allowFullScreen="">
                        </iframe>
                    </div>
                    <form className="contact-us-form">
                        <div className="">
                            <input type="text" className="input-name" placeholder="Name" required/>
                        </div>
                        <div className="">
                            <input type="email" className="input-email" placeholder="Email" required/>
                        </div>
                        <div className="">
                            <textarea className="textarea-message" placeholder="Message" rows="4" required/>
                        </div>
                        <button type="submit" className="btn btn-default contact-submit">Submit</button>
                    </form>        
                </div>
            </div>
    )
}

export default Contact