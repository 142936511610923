import React, { useEffect } from 'react'
import './editexecutives.css'

import {useSelector} from 'react-redux'
import { Redirect } from 'react-router-dom'
import EditExecutive from './editExecutive/EditExecutive.jsx'


const EditExecutives = () => {
    const executives = useSelector(state => state.executive.executives)
    const user = useSelector(state=>state.auth.user)

    const executivesArray = executives !== null && executives.map((executive,index)=>{
        return <EditExecutive executiveInfo={executive} key={index} />
    })

    // if(!user){
    //     return <Redirect to="/login" />
    // }

    return (
        <div className="center-everything edit-executives-container">
            <h2 className="edit-players-heading">Executives List</h2>
            {executivesArray}
        </div>
    )
}

export default EditExecutives
