import React from 'react'
import '../executive.css'
import './executiveList.css'
import nynfcLogo from './../../../../img/nynfc_logo.svg'
import exeCardBg from '../images/exe-card-bg.jpg'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { getPublicPlayer } from '../../../../actions/publicPlayer'

const ExecutiveList = () => {
    const executives = useSelector(state => state.publicExecutives)
    const players= useSelector(state=>state.publicPlayers)
    const dispatch = useDispatch()
    let mainExecutives = []
    let president = []
    let vicePresident = []
    let treasurer = []
    let clubAdvisior = []
    let manager = []
    let sponser = []

    executives.publicExecutives.length && executives.publicExecutives.forEach(executive => {

        if (executive.position === 'president') {
            president.push(executive)
        }
        if (executive.position === 'vice president') {
            vicePresident.push(executive)
        }
        if (executive.position === 'club advisior') {
            clubAdvisior.push(executive)
        }
        if (executive.position === 'manager') {
            manager.push(executive)
        }
        if (executive.position === 'Treasurer') {
           treasurer.push(executive)
        }
        if (executive.position === 'sponsor') {
            sponser.push(executive)
        }
    })

    const handleClick=id=>{
        dispatch(getPublicPlayer(id))
    }

    mainExecutives = [...president, ...vicePresident, ...manager, ...clubAdvisior, ...treasurer, ...sponser]
    const executivePlayers = players.publicPlayers.length && players.publicPlayers.filter(player=>player.executive)
    const displayExecutives = executives.publicExecutives.length && mainExecutives.map((executive, idx) => {
        return (
            <div className="executive-card-wrapper" key={idx}>
                <div className="executive-card border-0">
                    <div className="executive-img-container">
                        <img src={exeCardBg} alt="" className="executive-img-bg" />
                        <img src={executive.imageUrl} alt="" className="card-img-top img-fluid mb-1 executive-img" />
                    </div>
                    <div className="card-body executive-card-body">
                        <h3 className="text-nowrap">{`${executive.firstName} ${executive.lastName}`}</h3>
                        <h5>{`${executive.position[0].toUpperCase()}${executive.position.slice(1)}`}</h5>
                        <div className="d-flex flex-row justify-content-center">
                            <div className="p-1">
                                <a href={executive.facebookUrl ? executive.facebookUrl : "#"}>
                                    <i className="fab fa-facebook fa-lg"></i>
                                </a>
                            </div>
                            <div className="p-1">
                                <a href={executive.instagramUrl ? executive.instagramUrl : "#"} disabled>
                                    <i className="fab fa-instagram fa-lg"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    const displayExecutivePlayers = executivePlayers.length && executivePlayers.map((executive, idx) => {
        return (
            <div className="executive-card-wrapper" key={idx}>
              <Link to={`/player-profile/${executive._id}`} onclick={()=>handleClick(executive._id)}>
                <div className="executive-card border-0">
                        <div className="executive-img-container">
                            <img src={exeCardBg} alt="" className="executive-img-bg" />
                            <img src={executive.imageUrl} alt="" className="card-img-top img-fluid mb-1 executive-img" />
                        </div>
                        <div className="card-body executive-card-body">
                            <h3 className="text-nowrap">{`${executive.firstName} ${executive.lastName}`}</h3>
                            <h5>{`Executive/${executive.position[0].toUpperCase()}${executive.position.slice(1)}`}</h5>
                            <div className="d-flex flex-row justify-content-center">
                                <div className="p-1">
                                    <a href={executive.facebookUrl ? executive.facebookUrl : "#"}>
                                        <i className="fab fa-facebook fa-lg"></i>
                                    </a>
                                </div>
                                <div className="p-1">
                                    <a href={executive.instagramUrl ? executive.instagramUrl : "#"}>
                                        <i className="fab fa-instagram fa-lg"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
              </Link>
            </div>
        )
    })
    return (
        <div className="executive-list-main-container">
            <div className="executive-list-logo-top">
                <img className="executive-list-logo" src={nynfcLogo} alt="logo" />
                <h2>Executive Members</h2>
            </div>
            <div className="container-fluid executive-list-fluid">
                <div className="container executive-list-container">
                    <div className="executive-cards-wrapper mb-1">
                        {displayExecutives}
                    </div>
                </div>
            </div>
            <div className="container-fluid executive-list-fluid">
                <div className="container executive-list-container">
                    <div className="executive-cards-wrapper mb-1">
                        {displayExecutivePlayers}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExecutiveList
