import {
    SET_PUBLIC_EXECUTIVES,
    SET_PUBLIC_EXECUTIVES_FAIL
} from '../actions/types'

const initialState = {
    publicExecutives:[]
}

export default function(state=initialState, {type,payload}){
    switch(type){
        case SET_PUBLIC_EXECUTIVES:
            return {...state, publicExecutives:payload}
        case SET_PUBLIC_EXECUTIVES_FAIL:
            return {...state, publicExecutives:[]}
        default:
            return state
    }
}   