import React from 'react'
import MouseScrollBtn from '../../buttons/scrollmousebutton/MouseScrollBtn'
import './about.css'

import logo from './images/nynfc_logo.png'

function About() {
return (
<div className="container-fluid about-fluid bg-light">
    <div className="container about-container">
        <div className="about-nynfc-logo">
            <img src={logo} alt="" className="about-nynfc-img" />
        </div>
        <div className="about-info">
            <div className="about-info-heading">
            <h1 className="about-h1">ABOUT
            <span className="txt-color">US</span></h1>
        </div>
        <div className="about-desc">
            <p className="text-justify text-dark">
                <strong>NYNFC</strong> stands for New York Nepalese Football Club, was first established in 2010.
                It is formed to unify all the Nepalese youth soccer players from various community. 
                It is registered as non-profit organization (501-c3) in the state of NY.
                <br />
                 NYNFC always participates in various 
                tournaments in and out of the state year round, winning major tournaments. 
                Our motto is to unify all players 
                and welcomes any new player to join the team. 
                <br />
                NYNFC collects a yearly membership of $100 from our members and players. We offer free membership 
                for students. Come join us!
            </p>
            {/* <div className="about-info-button">Read More</div> */}
            <div className='d-flex justify-content-center align-items-center'>
                <MouseScrollBtn />
            </div>
            </div>
        </div>
    </div>
</div>
)
}

export default About


