import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import nynfcLogo from './../../img/nynfc_logo.svg'
import {getPublicPlayer, getPublicPlayers} from '../../actions/publicPlayer'
import {useSelector, useDispatch} from 'react-redux'
import CaptainIcon from '../icons/CaptainIcon'


function Playerprofile(props){
    const playerInfo = useSelector(state=>state.publicPlayers)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(playerInfo.publicPlayers.length){
            dispatch(getPublicPlayer(props.match.params.id))
        }
    },[playerInfo.publicPlayers])
    
    return (
        <div className="container-fluid text-center player-profile-container" style={{minHeight:"95vh", background:"#003366"}}>
            <div className="row player-profile-row">
                <img src={nynfcLogo} className="nynfc-profile-img" alt=""/>
                <h1>{playerInfo.publicPlayer._id && `${playerInfo.publicPlayer.firstName} ${playerInfo.publicPlayer.lastName}`}</h1>
                <div className="d-flex flex-row justify-content-center social-media p-2">
                    <div className="pr-2">
                        <a href={playerInfo.publicPlayer.facebookUrl ? playerInfo.publicPlayer.facebookUrl : "#"}>
                            <i className="fab fa-facebook fa-lg"></i>
                        </a>
                    </div>
                    <div className="pr-2">
                        <a href={playerInfo.publicPlayer.instagramUrl ? playerInfo.publicPlayer.instagramUrl : "#"}>
                            <i className="fab fa-instagram fa-lg contact-icon-in"></i>
                        </a>
                    </div>
                   {playerInfo.publicPlayer.linkedInUrl && (
                        <div className="pr-2">
                            <a href={playerInfo.publicPlayer.linkedInUrl ? playerInfo.publicPlayer.linkedInUrl : "#"}>
                                <i className="fab fa-linkedin fa-lg"></i>
                            </a>
                        </div>
                   )}
                     {playerInfo.publicPlayer.webUrl &&(
                        <div className="pr-2">
                            <a href={playerInfo.publicPlayer.webUrl ? playerInfo.publicPlayer.webUrl : "#"}>
                                <i class="fas fa-globe fa-lg" style={{color:"gray"}}></i>
                            </a>
                        </div>
                    )}
                </div>  
            </div>
            <img src={playerInfo.publicPlayer.imageUrl && playerInfo.publicPlayer.imageUrl} alt="" className="img-fluid mb-4 player-profile-img" />  
            {playerInfo?.publicPlayer?.firstName?.toLowerCase() === 'swasthik' && (
                <div className='d-flex flex-row justify-content-center'>
                    <CaptainIcon width='100px' fontSize='25px'/>
                </div>
            )}
           <div className="center-player-info">
            <div className="container player-info-container">
                    <h1 className="player-information-h1">Player Info</h1>
                    <div className="row player-info-row"> 
                        <div className="text-left player-info-col">
                            <div className="player-info-col-left">
                                <h5>Place of Birth</h5>
                                <h5>Age</h5>
                                <h5>Height</h5>
                                {/* <h5>Weight</h5> */}
                                <h5>Position</h5>
                                <h5>Current Team</h5>
                                <h5>Reside In</h5>
                                <h5>Membership Paid</h5>
                            </div>
                            <div className="player-info-col-right">
                                <h5>{playerInfo.publicPlayer.lastName.toLowerCase() === 'gyeltshen' || playerInfo.publicPlayer.lastName.toLowerCase() === 'younten' ? "Bhutan" : "Nepal"}</h5>
                                <h5>{playerInfo.publicPlayer.age ? playerInfo.publicPlayer.age : "NA"}</h5>
                                <h5>{playerInfo.publicPlayer.height ? `${playerInfo.publicPlayer.height}ft`: "NA"}</h5>
                                {/* <h5>180lbs</h5> */}
                                <h5>{playerInfo.publicPlayer.position ? `${playerInfo.publicPlayer.position[0].toUpperCase()}${playerInfo.publicPlayer.position.slice(1)}`: "NA"}</h5>
                                <h5>NYNFC</h5>
                                <h5>{playerInfo.publicPlayer.reside ? `${playerInfo.publicPlayer.reside.toUpperCase()}` : "NA"}</h5>
                                <h5>{playerInfo.publicPlayer.membership ? <i class="fas fa-check fa-lg paid"></i> : <i class="fas fa-times unpaid"></i>}</h5>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Playerprofile
