import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Toaster} from 'react-hot-toast'


ReactDOM.render(<>
    <App />
    <Toaster position='top-center' reverseOrder={false} />
</>, document.getElementById('root'));  
