import axios from 'axios'
import toast from 'react-hot-toast'
import {
    SET_PLAYER,
    SET_PLAYER_FAIL,
    SET_PLAYERS,
    SET_PLAYERS_FAIL,
    DELETE_PLAYER,
    DELETE_PLAYER_FAIL
} from './types'

export const addPlayer = (imageData) => async dispatch=>{
    const config = {
        headers:{
            'Content-type':'multipart/form-data'
        }
    }
    try {
        let {data} = await axios.post('https://erudite-scarab-311921.uc.r.appspot.com/api/player', imageData,config)
        if(data.player){
            dispatch({
                type:SET_PLAYER,
                payload:data.player
            })
            toast.success("Successfully added!")
            return true
        }
    } catch (err) {
        dispatch({
            type:SET_PLAYER_FAIL
        })
        toast.error("Something went wrong!")
    }
}

export const getPlayers = () => async dispatch => {
    try {
        let {data} = await axios.get('https://erudite-scarab-311921.uc.r.appspot.com/api/player')
        dispatch({
            type:SET_PLAYERS,
            payload:data.players
        })
    } catch (err) {
        dispatch({
            type:SET_PLAYERS_FAIL
        })
    }
}

export const updatePlayer = (imageData, id) => async dispatch=>{
    const config = {
        headers:{
            'Content-type':'multipart/form-data'
        }
    }
    try {
        let updatedPlayer = await axios.post(`https://erudite-scarab-311921.uc.r.appspot.com/api/player/${id}`,imageData,config)
        if(updatedPlayer){
            toast.success("Successfully updated!")
        }
    } catch (err) {
        toast.error("Something went wrong!")
    }
}

export const deletePlayer = (id) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'application/json'
        }
    }
    const body = JSON.stringify({id:id})
    try {
        let {data} = await axios.delete(`https://erudite-scarab-311921.uc.r.appspot.com/api/player/${id}`,body,config)
        if(data){
           dispatch({
               type:DELETE_PLAYER,
               payload:data.player
           })
           toast.success("Successfully deleted!")
        }
    } catch (err) {
      dispatch({
          type:DELETE_PLAYER_FAIL
      })
      toast.error("Something went wrong!")
    }
}
