import React from 'react'
import fundRaise from './images/fund_raise.png'
import NH from './images/NH_winning.png'
import selection from './images/selection.png'
import history from './images/nynfc_history.png'

import './news.css'

const News =()=>{
    return(
        <div className="container-fluid news-container-main text-center ">
            <h1 id="news-heading">Our Work</h1>
            <div className="container news-container p-4">
                <div className="news-col-left">
                        <iframe 
                        title="news-iframe"
                        className="news-frame"
                        src="https://www.youtube.com/embed/dGP1gqfAHhs" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                        </iframe>
                </div>
                <div className="news-col-right">
                    <div className="news-col-right-grid">
                        <a href='https://qns.com/story/2015/05/26/queens-based-nepali-soccer-team-raises-over-4k-for-earthquake-relief/' alt=""className="news-inner"> 
                            <img src={fundRaise} className="news-img"alt="" />
                        </a>
                        <a href='https://vishwanews.com/archives/1136' alt="" className="news-inner">
                            <img src={NH} className="news-img"alt="" />
                        </a>
                        <a href='https://www.texasnepal.com/search-for-national-football-team-players-from-usa-uk-and-aus/' alt="" className="news-inner">
                            <img src={selection} className="news-img"alt="" />
                        </a>
                        <a href='https://uk.gofundme.com/f/ksinr0' alt="" className="news-inner">
                            <img src={history} className="news-img"alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News

