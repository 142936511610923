import React,{useState} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'

import'./trainingsession.css'

function Trainingsession(){
    const schedule = useSelector(state=>state.publicTraining.trainingSchedule)
    if(schedule.trainingSchedule){
        var {day_one,day_two,
            meet_time_one,meet_time_two,
            meetup_loc_one,meetup_loc_two,
            playing_loc_one,
            playing_loc_two,
            playing_time_one,
            playing_time_two
        } = schedule.trainingSchedule
    }

    const convertTime = time => {
        return moment(time,'HH:mm:ss').format('h:mm A');
    }
    return (
        <div>
            <div className="container-fluid training-session-container-main text-center p-5">
                <div className="container training-session-container">
                    <h1 id="training-session-heading">Training Session</h1>
                    <div className="training-session-wrapper">
                        <div className="p-4 training-session-info">
                            <h2>{day_one ? day_one : "WEDNESDAY"}</h2>
                                <div className="training-session-innerdiv">
                                <h6>Time: {meet_time_one ? convertTime(meet_time_one) : "5pm"}</h6>
                                <h5>@ {meetup_loc_one ? meetup_loc_one : `Rainbow Bakery & Cafe`}</h5>
                                <small>OR</small>
                                <h5>{ playing_time_one ? convertTime(playing_time_one)+" ":"6pm" }|{playing_loc_one ? playing_loc_one : "randall's island"}</h5>
                            </div>
                        </div>
                        <div className="p-4 training-session-info">
                            <h2>{day_two ? day_two : "SATURDAY"}</h2>
                            <div className="training-session-innerdiv">
                            <h6>Time: {meet_time_two ? convertTime(meet_time_two ): "10am"}</h6>
                            <h5>@ {meetup_loc_two ? meetup_loc_two : `Rainbow Bakery & Cafe`}</h5>
                                <small>OR</small>
                                <h5>{ playing_time_two ? convertTime(playing_time_two)+" ":"11am" }|{playing_loc_two ? playing_loc_two : "randall's island"}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trainingsession 