import React,{Fragment,useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Link, Redirect} from 'react-router-dom'
import {setAlert} from './../../actions/alert'
import {register} from './../../actions/auth'


function Register({setAlert, register, isAuthenticated}) {
    const [formData, setFormData]=useState({
        name:'',
        email:'',
        password:'',
        password2:''
    });
    const {name,email,password,password2} = formData;
    const onChange = e => setFormData({...formData, [e.target.name]:e.target.value})

    const onSubmit= async e =>{
        e.preventDefault();
        if(password !== password2) {
            setAlert('Password do not match','danger')
        } else {
           register({name,email,password})
        }
    }
    if(isAuthenticated){
        return <Redirect to='/' />
    }
    return (
        <div className="container-fluid register-container-main text-center">
            <div className="container register-container">
                <h1 className='register-heading'>Sign Up</h1>
            </div>
            <p className='lead form-p mb-5'>
                <i className="fas fa-user" /> Create Your Account
            </p>
            <div className="container nynfc-form-container">
                    <form className="form nynfc-form" onSubmit={e=>onSubmit(e)}>
                        <div className='form-group-x'>
                            <input 
                            type='text' 
                            placeholder='Name' 
                            name='name' 
                            value={name}
                            onChange={e=>onChange(e)}
                            required 
                            />
                        </div>
                        <div className='form-group-x'>
                            <input type='email' 
                            placeholder='Email address' 
                            name='email' 
                            value={email}
                            onChange={e=>onChange(e)}
                            required 
                            />
                        </div>
                        <div className='form-group-x'>
                            <input 
                                type='password'
                                placeholder='Password'
                                name='password'
                                minLength='6'
                                value={password}
                                onChange={e=>onChange(e)}
                            />
                        </div> 
                        <div className='form-group-x'>
                            <input 
                            type='password'
                            placeholder='Confirm Password'
                            name='password2'
                            minLength='6'
                            value={password2}
                            onChange={e=>onChange(e)}
                            />
                        </div>
                        <input type='submit' className='btn btn-primary' value='Register' />
                    </form>
            </div>
            <p className='form-p'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </p>
        </div>
    )
}


Register.propTypes = {
setAlert:PropTypes.func.isRequired,
register:PropTypes.func.isRequired,
isAuthenticated:PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
    isAuthenticated:state.auth.isAuthenticated
})

export default connect(mapStateToProps,{setAlert,register})(Register)
