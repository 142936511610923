import React, { Fragment, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Navbar from './components/layout/ Navbar'
import Landing from './components/layout/Landing'
import Login from './components/auth/Login'
import Register from './components/auth/Register'
import Footer from './components/layout/Footer/Footer.jsx'
import Trophyhistory from './components/layout/Trophyhistory/Trophyhistory.jsx'
import Alert from './components/layout/Alert'
import { loadUser } from './actions/auth'
import { getPublicPlayers } from './actions/publicPlayer'
import { getPublicExecutives } from './actions/publicExecutive'
import { getPublicTraining } from './actions/publicTraining'
import setAuthToken from './utils/setAuthToken'
import Playerprofile from './components/player/Playerprofile'
import PlayersLanding from './components/player/PlayersLanding'
import Fullgallery from './components/gallery/Fullgallery'
import ExecutiveList from './components/layout/Executive/ExecutiveList/ExecutiveList.jsx'
import Finance from './components/finance/Finance.jsx';


//redux
import { Provider } from 'react-redux'
import store from './store'
import AdminNav from './components/adminDashboard/adminNav/AdminNav.jsx';

if (localStorage.token) {
  setAuthToken(localStorage.token)
}
const App = () => {

  useEffect(() => {
    store.dispatch(loadUser())
    store.dispatch(getPublicPlayers())
    store.dispatch(getPublicExecutives())
    store.dispatch(getPublicTraining())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <Route exact path="/" component={Landing} />
          <Alert />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/trophy-history" component={Trophyhistory} />
            <Route exact path='/player-profile/:id' component={Playerprofile} />
            <Route exact path='/view-players' component={PlayersLanding} />
            <Route exact path='/view-gallery' component={Fullgallery} />
            <Route exact path='/view-executives' component={ExecutiveList} />
            <Route exact path='/admin/dashboard/player' component={AdminNav} />
            <Route exact path='/admin/dashboard/executive' component={AdminNav} />
            <Route exact path="/admin/dashboard/training-schedule" component={AdminNav} />
            <Route exact path="/finance" component={Finance} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App;
