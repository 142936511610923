import React,{Fragment, useState}from 'react'
import PropTypes from 'prop-types'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {login} from '../../actions/auth'

function Login({login, isAuthenticated}) {

    const[formData, setFormData] = useState({
        email:'',
        password:''
    })
    const {email,password} = formData

    const onChange = e => setFormData({...formData,[e.target.name]:e.target.value})

    const onSubmit = e =>{
        e.preventDefault()
        login(email,password)
    }
    // Redirect if logged in
    if(isAuthenticated){
        return <Redirect to='/admin/dashboard/player' />
    } 
        return (
            <div className="container-fluid register-container-main text-center">
                <div className="container register-container">
                    <h1 className='register-heading'>Sign In</h1>
                </div>
                <p className='lead form-p mb-5'>
                    <i className="fas fa-user" /> Sign into Your Account
                </p>
                <div className="container nynfc-form-container">     
                        <form className="form nynfc-form" onSubmit={e=>onSubmit(e)}>
                            <div className='form-group-x'>
                                <input 
                                type='email' 
                                placeholder='Email address'
                                 name='email' 
                                 required 
                                 value={email}
                                 onChange={e=>onChange(e)}
                                 />
                            </div>
                            <div className='form-group-x'>
                                <input 
                                    type='password'
                                    placeholder='password'
                                    name='password'
                                    minLength='6'
                                    value={password}
                                    onChange={e=>onChange(e)}
                                />
                            </div> 
                            <input type='Submit' className='btn btn-primary' value='Login' />
                        </form>
                </div>
                <p className='form-p'>
                   Don't have an account? <Link to='/register'>Sign Up</Link>
                </p>
            </div>
        )
    
}

Login.propTypes = {
login:PropTypes.func.isRequired,
isAuthenticated:PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated:state.auth.isAuthenticated
})

export default connect(mapStateToProps,{login})(Login)
