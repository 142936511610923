import {
    SET_EXECUTIVES,
    SET_EXECUTIVES_FAIL,
    SET_EXECUTIVE,
    SET_EXECUTIVE_FAIL,
    DELETE_EXECUTIVE,
    DELETE_EXECUTIVE_FAIL
} from '../actions/types'


const initialState = {
    executives:[],
    executive:{}
}

export default function(state=initialState, {type,payload}){
    switch(type){
        case SET_EXECUTIVES:
            return{...state, executives:payload}
        case SET_EXECUTIVE:
            return{...state, executive:payload, executives:[...state.executives, payload]}
        case DELETE_EXECUTIVE:
            return {
                ...state,
                executives:state.executives.filter(executive=>executive._id !== payload._id)
            }
        case SET_EXECUTIVES_FAIL:
        case DELETE_EXECUTIVE_FAIL:
            return state
        case SET_EXECUTIVE_FAIL:
            return {...state, executive:{}}
        default:
            return state
    }
}