import React,{useState} from 'react'


import './finance.css'

const FinanceForm = () => {
    const handleOnSubmit = (e) =>{
        e.preventDefault()
        console.log(formData)
    }
    const [formData, setFormdata] = useState({
        title:'',
        name:'',
        type:'income',
        amount:0,
        date:''
    })
    const {title,name,type,amount,date} = formData
    const onChange = e => setFormdata({...formData,[e.target.name]:e.target.value})
   
    return (
        <div className="container-fluid finance-form-fluid">
            <div className="container finance-form-container">
                <div className="finance-form-border">
                    <div className="finance-form-heading">
                        <h3>Add New Transaction</h3>
                    </div>
                    <form onSubmit={(e)=>handleOnSubmit(e)} className="form nynfc-form finance-form">
                        <div className="finance-form-input">
                            <small>Enter Title</small>
                            <input 
                            type='text' 
                            placeholder='Income/Expense title' 
                            name='title' 
                            value={title}
                            onChange={(e)=>onChange(e)}
                            required />
                        </div>
                        <div className="finance-form-input">
                            <small>Enter Name</small>
                            <input 
                            type='text' 
                            placeholder='Name of a person | paid/received' 
                            name='name' 
                            value={name}
                            onChange={(e)=>onChange(e)}
                            required />
                        </div>
                        <div className="finance-form-input">
                            <small>Select a type*</small>
                            <select name="type" id="type" value={type} onChange={(e)=>onChange(e)} required>
                                <option value="income">Income</option>
                                <option value="expense">Expense</option>
                            </select>
                        </div>
                        <div className="finance-form-input">
                            <small>Amount$</small>
                            <input 
                            type="number"
                            min="1"
                            name="amount"
                            value={amount}
                            onChange={(e)=>onChange(e)}
                            required
                            />
                        </div>
                        <div className="finance-form-input">
                            <small>Enter Date</small>
                            <input type="date" name="date" value={date} onChange={(e)=>onChange(e)} required/>
                        </div>
                        <div className="finance-submit">
                            <input type='submit' className='btn btn-primary finance-submit-btn finance-number-input' value='ADD' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FinanceForm
