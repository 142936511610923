import React from 'react'
import './finance.css'
import EditFinance from './EditFinance.jsx'
const Transaction = (props) => {
    const mockTransaction = [
        {_id:1,title:"Walmart",name:"Tenzin Dai",type:"expense",amount:"200",date:"2021-07-03"},
        {_id:2,title:"Entery fee - Sherpa",name:"Tenzin Dai",type:"expense",amount:"350",date:"2021-06-03"},
        {_id:3,title:"Winning price-UNA",name:"Tenzin Dai",type:"income",amount:"4000",date:"2021-05-03"},
        {_id:4,title:"Member fee",name:"Tenzin Dai",type:"income",amount:"1500",date:"2021-06-10"}
    ]
    const expenses = mockTransaction.length && mockTransaction.filter(transaction=>transaction.type === "expense")
    const incomes = mockTransaction.length && mockTransaction.filter(transaction=>transaction.type === "income")

    const handleDelete = (id) =>{
        console.log("Deleted")
    }

    const displayExpenses = expenses.length && expenses.map((expense,idx)=>{
        return(
            <div className="card accordion-transaction-card" key={idx}>
                <div className="card-header transaction-card-header" id="headingOne">
                    <div className="mb-0 accordion-pannel transaction-card-pannel">
                        <p>{`${expense.title[0].toUpperCase()}${expense.title.slice(1)}`} | <span className="expense-clr">-{`${expense.amount}`}</span></p>
                        <div className="accordion-btn-group">
                            <button className="btn btn-warning accordion-edit-btn mr-1" 
                                data-toggle="collapse" 
                                data-target={`#collapse${expense._id}`} 
                                aria-expanded="true" 
                                aria-controls={`collapse${expense._id}`}>
                                    Edit
                                </button>
                                <button className="btn btn-danger" onClick={()=>handleDelete(expense._id)}>
                                    Delete
                                </button>
                        </div>
                    </div>
                </div>
                <div id={`collapse${expense._id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="">
                        <EditFinance transaction={expense} />
                    </div>
                </div>
            </div>
        )
    })

    const displayIncomes = incomes.length && incomes.map((income,idx)=>{
        return(
            <div className="card accordion-transaction-card" key={idx}>
                <div className="card-header transaction-card-header" id="headingOne">
                    <div className="mb-0 accordion-pannel transaction-card-pannel">
                        <p>{`${income.title[0].toUpperCase()}${income.title.slice(1)}`} | <span className="income-clr">+{`${income.amount}`}</span></p>
                        <div className="accordion-btn-group">
                            <button className="btn btn-warning accordion-edit-btn mr-1" 
                                data-toggle="collapse" 
                                data-target={`#collapse${income._id}`} 
                                aria-expanded="true" 
                                aria-controls={`collapse${income._id}`}>
                                    Edit
                                </button>
                                <button className="btn btn-danger" onClick={()=>handleDelete(income._id)}>
                                    Delete
                                </button>
                        </div>
                    </div>
                </div>
                <div id={`collapse${income._id}`} className="collapse finance-collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="">
                        <EditFinance transaction={income} />
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="container-fluid transaction-fluid">
            <div className="container filter-date-container">
                <div className="filter-input">
                    <select name="type" id="type">
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                    </select>
                </div>
                </div>
            <div className="container transaction-container">
                <div className="income-container">
                    <h4 className="transaction-income-heading">Income</h4>
                    {displayIncomes}
                </div>
                <div className="expense-container">
                    <h4 className="transaction-expense-heading">Expense</h4>
                    {displayExpenses}
                </div>
            </div>
        </div>
    )
}

export default Transaction
