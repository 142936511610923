import React,{useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {addPlayer, getPlayers} from '../../../actions/player'
import {Redirect} from 'react-router-dom'



import './playerupload.css'
import EditPlayers from './editplayers/EditPlayers.jsx'

const PlayerUpload = () => {
const user = useSelector(state=>state.auth.user)
const dispatch = useDispatch()
useEffect(() => {
    dispatch(getPlayers())
}, [dispatch])

const [formData, setFormData] = useState({
    firstName:'',
    lastName:'',
    email:'',
    age:0,
    number:'',
    reside:'',
    feet:0,
    inch:0,
    position:'',
    team:'',
    membership:'',
    executive:'',
    facebookUrl:'',
    instagramUrl:'',
    linkedInUrl:'',
    webUrl:'',
})

let {firstName,lastName,email,age,phone,reside,feet,inch,position,membership,executive,facebookUrl,instagramUrl,linkedInUrl,webUrl} = formData

const [fileData, setFileData] = useState({
    playerImg:''
})

let {playerImg} = fileData

const onChange=(e)=>setFormData({...formData, [e.target.name]:e.target.value})
const onChangeFile=(e)=>setFileData({...fileData, [e.target.name]:e.target.files[0]})

const handleonSubmit =async(e)=>{
    e.preventDefault()
    const imageData = new FormData()
    imageData.append('playerImg', playerImg)
    imageData.append('firstName', firstName)
    imageData.append('lastName', lastName)
    imageData.append('email', email)
    imageData.append('age', age)
    imageData.append('phone', phone)
    imageData.append('reside', reside)
    imageData.append('height', `${feet}.${inch}`)
    imageData.append('position', position)
    imageData.append('membership',membership)
    imageData.append('executive',executive)
    imageData.append('facebookUrl',facebookUrl)
    imageData.append('instagramUrl', instagramUrl)
    imageData.append('linkedInUrl', linkedInUrl)
    imageData.append('webUrl',webUrl)

   let res = dispatch(addPlayer(imageData))
   if (res){
       setFormData({
        firstName:'',
        lastName:'',
        email:'',
        age:0,
        phone:'',
        reside:'',
        feet:0,
        inch:0,
        position:'',
        team:'',
        membership:'',
        executive:'',
        facebookUrl:'',
        instagramUrl:'',
        linkedInUrl:'',
        webUrl:'',
       })
       setFileData({
        playerImg:''
       })
   }

}

let fileName = "Player Image"
if(playerImg){
    fileName=playerImg.name
}

//    if(!user){
//       return <Redirect to="/" />
//    }

    return (
        <div className="container-fluid player-upload-fluid">
            <div className="container player-upload-container">
                <div className="row">
                    <form onSubmit={e=>handleonSubmit(e)} className="player-upload-form">
                        <div className="player-upload-heading-bg">
                            <h1 className="player-upload-heading">Add New Player</h1>
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="First name"
                                name="firstName"
                                value={firstName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Last name"
                                name="lastName"
                                value={lastName}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="email"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={email}
                                required
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Phone number"
                                name="phone"
                                value={phone}
                                pattern=".{10,10}"
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Reside In"
                                name="reside"
                                value={reside}
                            />
                        </div>
                        <div className="form-group-x">
                            <label htmlFor="ageId">Age:</label>
                            <input
                                type="number"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Age"
                                name="age"
                                value={age}
                                min="10"
                                max="100"
                                id="ageId"
                            />
                        </div>
                        <div className="form-group-x">
                            <label htmlFor="heightId">Height:</label>
                            <div className="height-info">
                                <input
                                    type="number"
                                    onChange={e=>onChange(e)}
                                    className="form-control"
                                    placeholder="ft"
                                    name="feet"
                                    value={feet}
                                    min="1"
                                    max="10"
                                    id="heightId"
                                />
                                <p>ft</p>
                                <input
                                    type="number"
                                    onChange={e=>onChange(e)}
                                    className="form-control"
                                    placeholder="inch"
                                    name="inch"
                                    value={inch}
                                    min="0"
                                    max="11"
                                />
                                <p>inch</p>
                        </div>
                        </div>
                        <div className="form-group-x">
                            <select className="form-control" 
                                name="position" 
                                value={position} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Position?</option>
                                <option value="goalkeeper">GoalKeeper</option>
                                <option value="defender">Defender</option>
                                <option value="fullback">Fullback</option>
                                <option value="centerback">Centerback</option>
                                <option value="midfielder">Midfielder</option>
                                <option value="winger">Winger</option>
                                <option value="striker">Striker</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        {/* <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Any other club you play for? eg teamTibet,teamBhutan"
                                name="team"
                                value={team}
                            />
                        </div> */}

                        <div className="form-group-x">
                            <select className="form-control" 
                                name="membership" 
                                value={membership} 
                                onChange={e=>onChange(e)}
                                required
                                >
                                <option disabled value="">Membership paid?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <select className="form-control" 
                                name="executive" 
                                value={executive} 
                                onChange={e=>onChange(e)}
                                >
                                <option disabled value="">Is Executive?</option>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="facebook Url - Optional"
                                name="facebookUrl"
                                value={facebookUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="instagram Url - Optional"
                                name="instagramUrl"
                                value={instagramUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="LinkedIn Url - Optional"
                                name="linkedInUrl"
                                value={linkedInUrl}
                            />
                        </div>
                        <div className="form-group-x">
                            <input
                                type="text"
                                onChange={e=>onChange(e)}
                                className="form-control"
                                placeholder="Web Url - Optional"
                                name="webUrl"
                                value={webUrl}
                            />
                        </div>

                        <div className="custom-file">
                        <input 
                            type="file" 
                            name="playerImg" 
                            onChange={e=>onChangeFile(e)}
                            className="custom-file-input" 
                            id="customFile" 
                            required
                            />
                            <label className="custom-file-label"  htmlFor="customFile">{fileName}</label>
                        </div>
                        <div className="center-button">
                            <button type='Submit' className='btn btn-primary my-4 px-5 player-upload-button'>ADD</button>
                        </div>
                    </form>
                </div>
                <div>
                    <EditPlayers />
                </div>
            </div>
        </div>
    )
}

export default PlayerUpload
