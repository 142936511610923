export const handleonClick=(index,images)=>{
    document.querySelector('.modal').style.display = "block"
    document.querySelector('.img-fluid-custom').setAttribute('src',images[index])
    document.querySelector('.img-fluid-custom').setAttribute('data-index',index)
}

export const nextImage=(images)=>{
    let index = +document.querySelector('.img-fluid-custom').getAttribute('data-index')
    index===images.length - 1 ? index=0 : index+=1
    document.querySelector('.img-fluid-custom').setAttribute('src',images[index])
    document.querySelector('.img-fluid-custom').setAttribute('data-index',index)     
}

export const prevImage=(images)=>{
    let index = +document.querySelector('.img-fluid-custom').getAttribute('data-index')
    index === 0 ? index = images.length -1 : index-=1
    document.querySelector('.img-fluid-custom').setAttribute('src',images[index])
    document.querySelector('.img-fluid-custom').setAttribute('data-index',index)
}

export const hideModal=(e)=>{ 
    if(e.target.className === "modal"){
        e.target.style.display="none"
    }
}