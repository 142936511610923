import axios from 'axios'
import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { getTraining, updateTraining } from '../../../actions/training'

import './trainingschedule.css'

const TrainingSchedule = () => {

    const dispatch = useDispatch()
    const trainingInfo = useSelector(state=>state.training.training)

    const [formData, setFormData] = useState({
        meet_time_one:'',
        meet_time_two:'',
        day_one:'Wednesday',
        day_two:'Saturday',
        meetup_loc_one:'Rainbow Bakery & Cafe',
        meetup_loc_two:'Rainbow Bakery & Cafe',
        playing_loc_one:'Randalls Island',
        playing_loc_two:'Randalls Island',
        playing_time_one:'',
        playing_time_two:''
    })

    useEffect(()=>{
        dispatch(getTraining())
    },[dispatch])

    useEffect(()=>{
        setFormData({
            meet_time_one:trainingInfo.meet_time_one,
            meet_time_two:trainingInfo.meet_time_two,
            day_one:trainingInfo.day_one,
            day_two:trainingInfo.day_two,
            meetup_loc_one:trainingInfo.meetup_loc_one,
            meetup_loc_two:trainingInfo.meetup_loc_two,
            playing_loc_one:trainingInfo.playing_loc_one,
            playing_loc_two:trainingInfo.playing_loc_two,
            playing_time_one:trainingInfo.playing_time_one,
            playing_time_two:trainingInfo.playing_time_two
        })
    },[trainingInfo])

    let {
        meet_time_one,
        meet_time_two,
        day_one,
        day_two,
        meetup_loc_one,
        meetup_loc_two,
        playing_loc_one,
        playing_loc_two,
        playing_time_one,
        playing_time_two
    } = formData

    const onChange =(e)=>setFormData({...formData,[e.target.name]:e.target.value})

    const onSubmit = async(e) =>{
        e.preventDefault()
        if(trainingInfo._id){
            dispatch(updateTraining(trainingInfo._id, formData))
        }
    }

    return (
        <div className="container-fluid fluid-training-schedule" id="fluid-training-schedule">
            <div className="container training-schedule-container">
                <div className="row training-row">
                    <div className="training-upload-heading-bg">
                        <h1 className="executive-upload-heading">Update Training Session</h1>
                    </div>
                </div>
                <div className="row form-row training-row">
                    <form className="training-schedule-form" onSubmit={(e)=>onSubmit(e)}>
                        <div className="form-group-x">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control text-center"
                            name="day_one"
                            value={day_one}
                            placeholder="Enter a Day"
                            style={{fontWeight:"bold"}}
                            required
                            />
                        </div>
                        <div className="form-group-x d-flex">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="meetup_loc_one"
                            value={meetup_loc_one}
                            placeholder="Enter a Meetup location"
                            required
                            />
                            <input 
                            type="time"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="meet_time_one"
                            value={meet_time_one}
                            required
                            />
                        </div>
                        
                        <div className="form-group-x d-flex">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="playing_loc_one"
                            value={playing_loc_one}
                            placeholder="Enter a playing location"
                            required
                            />
                            <input 
                            type="time"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="playing_time_one"
                            value={playing_time_one}
                            required
                            />
                        </div>
    
                        <hr className="line-break"/>

                        <div className="form-group-x">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control text-center"
                            name="day_two"
                            value={day_two}
                            placeholder="Enter a Day"
                            style={{fontWeight:"bold"}}
                            required
                            />
                        </div>
                        <div className="form-group-x d-flex">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="meetup_loc_two"
                            value={meetup_loc_two}
                            placeholder="Enter a Meetup location"
                            required
                            />
                            <input 
                            type="time"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="meet_time_two"
                            value={meet_time_two}
                            required
                            />
                        </div>
                        
                        <div className="form-group-x d-flex">
                            <input 
                            type="text"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="playing_loc_two"
                            value={playing_loc_two}
                            placeholder="Enter a playing location"
                            required
                            />
                            <input 
                            type="time"
                            onChange={e=>onChange(e)}
                            className="form-control"
                            name="playing_time_two"
                            value={playing_time_two}
                            required
                            />
                        </div>
                        
                        <div className="form-group-x center-training-button">
                            <button type="submit">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TrainingSchedule
