import React from 'react'

import './mainview.css'

function Mainview() {
return (
<div className="bg-light">
    <div className="container-fluid mainview-fluid">
        <div className="container mainview-container">
            <div className="mainview-card">
                <div className="mainview-card-inner">
                    <p>Welcome to</p>
                    <h1>NYNFC</h1>
                    {/* <div className="mainview-button">Learn More</div> */}
                        <div class="arrow">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
)
}

export default Mainview
