export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const SET_PLAYERS = 'SET_PLAYERS'
export const SET_PLAYERS_FAIL = 'SET_PLAYERS_FAIL'
export const SET_PLAYER = 'SET_PLAYER'
export const SET_PLAYER_FAIL = 'SET_PLAYER_FAIL'
export const DELETE_PLAYER = 'DELETE_PLAYER'
export const DELETE_PLAYER_FAIL = "DELETE_PLAYER_FAIL"
export const SET_EXECUTIVES = 'SET_EXECUTIVES'
export const SET_EXECUTIVES_FAIL = 'SET_EXECUTIVES_FAIL'
export const SET_EXECUTIVE = 'SET_EXECUTIVE'
export const SET_EXECUTIVE_FAIL = 'SET_EXECUTIVE_FAIL'
export const DELETE_EXECUTIVE = 'DELETE_EXECUTIVE'
export const DELETE_EXECUTIVE_FAIL = "DELETE_EXECUTIVE_FAIL"

export const SET_TRAINING = "SET_TRAINING"
export const SET_TRAINING_FAIL = "SET_TRAINING_FAIL"

export const SET_PUBLIC_PLAYERS = "SET_PUBLIC_PLAYERS"
export const SET_PUBLIC_PLAYERS_FAIL = "SET_PUBLIC_PLAYERS_FAIL"

export const SET_PUBLIC_PLAYER = "SET_PUBLIC_PLAYER"
export const SET_PUBLIC_PLAYER_FAIL = "SET_PUBLIC_PLAYER_FAIL"

export const SET_PUBLIC_EXECUTIVES = "SET_PUBLIC_EXECUTIVES"
export const SET_PUBLIC_EXECUTIVES_FAIL = "SET_PUBLIC_EXECUTIVES_FAIL"

export const SET_PUBLIC_TRAINING_SCHEDULE = "SET_PUBLIC_TRAINING_SCHEDULE"
export const  SET_PUBLIC_TRAINING_SCHEDULE_FAIL = "SET_PUBLIC_TRAINING_SCHEDULE_FAIL"