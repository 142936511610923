import React from 'react'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import './team.css'
import { getPublicPlayer } from '../../../actions/publicPlayer';
import CaptainIcon from '../../icons/CaptainIcon';

function Team() {
    function handleOnclick(){
        window.scroll({
            top:0,
            left:0,  
        });
    }
    const players= useSelector(state=>state.publicPlayers)
    const dispatch = useDispatch()
    let randomPlayers = []
    const getRandomPlayers = ()=>{
        let tempPlayers = [...players.publicPlayers]
        while (randomPlayers.length<4){
            const random = Math.floor(Math.random() * tempPlayers.length);
            if(!randomPlayers.some(player => player._id === tempPlayers[random]._id)){
                randomPlayers.push(tempPlayers[random])
            }
        }
    }
    if(players.publicPlayers.length && players.publicPlayers.length > 4){
        getRandomPlayers()
    } else {
        randomPlayers = players
    }
    const handleonClick = (id) =>{
        dispatch(getPublicPlayer(id))
    }
    const displayRandomPlayers = randomPlayers.length && randomPlayers.map((player,idx)=>{
        return(
            <div className="team-card border-0" key={idx}> 
                <div className="team-card-inner">
                    <Link to={`/player-profile/${player._id}`} onClick={()=>handleonClick(player._id)}>
                        <img src={player.imageUrl} alt="" className="card-img-top img-fluid mb-1" />  
                    </Link>  
                    {player?.firstName.toLowerCase() === 'swasthik' && (
                        <div className='card-captain-icon'>
                            <CaptainIcon />
                        </div> 
                    )}
                </div>
                <div className="team-card-body">
                    <Link to={`/player-profile/${player._id}`}>
                        <h4 className="team-card-name">
                            {`${player.firstName[0].toUpperCase()}${player.firstName.slice(1).toLowerCase()} ${player.lastName[0].toUpperCase()}${player.lastName.slice(1).toLowerCase()}`}
                        </h4>  
                        <h6>{`${player.position[0].toUpperCase()}${player.position.slice(1).toLowerCase()}`}</h6> 
                        {/* <h2 className="text-danger player-number">1</h2> */}
                    </Link>
                    <div className="d-flex flex-row justify-content-center">
                        <div className="px-1">
                            <a href={player.facebookUrl ? player.facebookUrl : "#"}>
                                <i className="fab fa-facebook fa-lg"></i>
                            </a>
                        </div>
                        <div className="px-1">
                            <a href={player.instagramUrl ? player.instagramUrl : "#"}>
                                <i className="fab fa-instagram fa-lg"></i>
                            </a>
                        </div>
                    </div>         
                </div>
            </div>
        )
    })
    return (
        <div className="container-fluid bg team-fluid" id="team">
        <div className="team-container py-4 text-center" >
            <div className="row mb-4">
                <div className="col mb-4">
                    <h2 style={{color:'white'}}  id="our-team-heading">Our Team</h2>
                </div>
            </div>
            <div className="team-card-wrapper">
                {displayRandomPlayers}
            </div>
        </div>
            <div className="row">
                <div className="col text-center">
                    <Link to="/view-players" alt="">
                        <button type="button" className="btn btn-outline waves-effect gallery-button" onClick={handleOnclick}>View More +</button>
                    </Link>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
    </div>
    )
}

export default Team
