import React from 'react'

import './history.css'
function History() {
    return(
        <div className="container-fluid history-fluid" id="history-bg">
            <div className="container  history-container py-5">
                <div className="history-wrapper text-center">
                    <div className="game-history" >
                        <h1>150+</h1>
                        <h3>MATCHES</h3>
                    </div>
                    <div className="game-history" >
                        <h1>40+</h1>
                        <h3>PLAYERS</h3>
                    </div>
                    <div className="game-history" >
                        <h1>30+</h1>
                        <h3>AWARDS</h3>
                     </div>
                    <div className="game-history" >
                        <h1>100+</h1>
                        <h3>MEMBERS</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History